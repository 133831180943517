import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, MEDICO} from "../constants/action-type";
import { CONSULTAR_MEDICOS, AGREGAR_MEDICO, ACTUALIZAR_MEDICO, ELIMINAR_MEDICO } from "../constants/Medico-action-type";


/* Funcion para loggin llamada logMiddleware */
const createMedicoRestMiddleware =(medicosURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [MEDICO]: new RestDataSource(medicosURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** MEDICO INICIO */                                    
                case CONSULTAR_MEDICOS:
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}

                            dataSources[action.dataType].GetData((dataMedico,mensaje)=>{ 
                                if(dataMedico===null){
                                    if(action.loading){next({type:PETICION_WS_ERROR});}
                                }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_MEDICOS,
                                    dataType:action.dataType,
                                    payload:dataMedico}); 
                                    if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataMedico.length});
                                    }
                                }
                            });      
                break;   

                case AGREGAR_MEDICO:
                    console.log("AGREGAR_MEDICO(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                            dataSources[action.dataType].SetData((dataMedico,mensaje)=>{ 
                                if(dataMedico===null){
                                    console.log("FALLA PETICION A MEDICO:"+mensaje);
                                    next({type:PETICION_WS_EXITOSA});
                                }else{
                                    console.log("AGREGAR_MEDICO (data):"+dataMedico);
                
                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                        type:AGREGAR_MEDICO,
                                        dataType:action.dataType,
                                        payload:dataMedico});
                                        
                                    let count=parseInt( getState().estadosReducer.registros) + 1;
                                    console.log("coun:"+count); 
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                                }
                            },action.payload);
                 break; 

                 case ACTUALIZAR_MEDICO:
                     next({type:PETICION_WS_SOLICITANDO});

                           dataSources[action.dataType].UpdateData((dataMedico,mensaje)=>{ 
                            if(dataMedico===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataMedico):"+dataMedico);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_MEDICO,
                                    dataType:action.dataType,
                                    payload:dataMedico});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);
                              
                 break;    

                 case ELIMINAR_MEDICO:
                   
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].DeleteData((dataMedico,mensaje)=>{ 
                        if(dataMedico===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se Elimina en Axios (dataMedico):"+JSON.stringify(dataMedico));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ELIMINAR_MEDICO,
                                dataType:action.dataType,
                                payload:dataMedico});

                            let count=parseInt( getState().estadosReducer.registros) - 1;
                            console.log("coun(eliminar):"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                            }
                        },action.payload);
        
                    break;  



 

        default:
                next(action);

    }
 }

}

export default createMedicoRestMiddleware;