import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import {ID_ROL_MEDICO,ID_ROL_PACIENTE } from "../../../utils/constantes";
import MedicoFormSelect from "../MedicoFormSelect";
import PacienteFormSelect from "../PacienteFormSelect";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class Page extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }

    goBack(){
        this.props.history.goBack();
    }


    render(){
	//	let //
	console.log("ERRORS :"+JSON.stringify(this.props.errors));
	let medicosList="";
	medicosList=(this.props.values.rol.CAT_ROL_id===ID_ROL_MEDICO)?
							<MedicoFormSelect MEDICO_id="rol.MEDICO_id"  
								medicoValue={this.props.values.rol.MEDICO_id}
								errors={this.props.errors}
								touched={this.props.touched} 
								handleChange={this.props.handleChange} 
								/>:null;
	let pacientesList="";
	pacientesList=(this.props.values.rol.CAT_ROL_id===ID_ROL_PACIENTE)?
							<PacienteFormSelect PACIENTE_id="rol.PACIENTE_id"  
								pacienteValue={this.props.values.rol.PACIENTE_id}
								INSTITUCION_id={this.props.values.usuario.INSTITUCION_id}
								errors={this.props.errors}
								touched={this.props.touched} 
								handleChange={this.props.handleChange} 
								/>:null;
														
        return (<form onSubmit={this.props.handleSubmit}> 
                    <Dialog
                    open={this.props.modal}
                    onClose={this.props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen

                    >
                    <AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.goBack} aria-label="close">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                {<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nuevo Usuario</Typography>}
                            </Typography>

                            <IconButton edge="start" color="inherit" onClick={this.props.handleSubmit} aria-label="close">
                                <DoneIcon  style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        </AppBar>

                        <DialogTitle id="form-dialog-title">Agregar Usuario</DialogTitle>
                        


							<DialogContent>
							<TextField
								error={this.props.touched.usuario&&this.props.touched.usuario.usuario&&this.props.errors.usuario&&this.props.errors.usuario.usuario? true:false}
								helperText={this.props.touched.usuario&&this.props.touched.usuario.usuario&&this.props.errors.usuario&&this.props.errors.usuario.usuario?this.props.errors.usuario.usuario:""}
								size="medium"
								margin="normal"
								id="usuario.usuario"
								label="usuario:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} 
								value={this.props.values.usuario.usuario}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.usuario&&this.props.touched.usuario.password&&this.props.errors.usuario&&this.props.errors.usuario.password? true:false}
								helperText={this.props.touched.usuario&&this.props.touched.usuario.password&&this.props.errors.usuario&&this.props.errors.usuario.password?this.props.errors.usuario.password:""}
								size="medium"
								margin="normal"
								id="usuario.password"
								label="password:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.usuario.password}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.usuario&&this.props.touched.usuario.correo&&this.props.errors.usuario&&this.props.errors.usuario.correo? true:false}
								helperText={this.props.touched.usuario&&this.props.touched.usuario.correo&&this.props.errors.usuario&&this.props.errors.usuario.correo?this.props.errors.usuario.correo:""}
								size="medium"
								margin="normal"
								id="usuario.correo"
								label="correo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.usuario.correo}
								onBlur={this.props.handleBlur}
							/>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple">INSTITUCION</InputLabel>
								<Select
									error={this.props.touched.usuario&&this.props.touched.usuario.INSTITUCION_id&&this.props.errors.usuario&&this.props.errors.usuario.INSTITUCION_id? true:false}
									size="medium"
									id="usuario.INSTITUCION_id"
									label="INSTITUCION_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.usuario.INSTITUCION_id}
									onBlur={this.props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									this.props.instituciones.map(institucion=> (
										<option key={institucion.id}  value={institucion.id}>{institucion.nombre}</option>)
									)}
								</Select>
							</FormControl>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple">PERFIL</InputLabel>
								<Select
									error={this.props.touched.rol&&this.props.touched.rol.CAT_ROL_id&&this.props.errors.rol&&this.props.errors.rol.CAT_ROL_id? true:false}
									size="medium"
									id="rol.CAT_ROL_id"
									label="CAT_ROL_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.rol.CAT_ROL_id}
									onBlur={this.props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									this.props.roles.map(rol=> (
										<option key={rol.id}  value={rol.id}>{rol.nombre}</option>)
									)}
								</Select>
							</FormControl>
							{
								medicosList
							}			
							{
								pacientesList
							}			

                            </DialogContent>
                                         
                    </Dialog>
                    </form>
                       
                )
    }
}
export default  withRouter(Page);
