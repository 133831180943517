import React,{Component} from "react";
import Page from "./page";

export default class CitaList extends Component{

    render(){
        let citaesFilter = this.props.citaes.filter((data)=>{
			if(data.MEDICO_id!=undefined){
				if(this.props.busqueda == null){
					return data;
				}else if(data.MEDICO_id.toLowerCase().includes(this.props.busqueda.toLowerCase())){
					return data;
				}
			}
         });   
  
        return< Page citaesFilter={citaesFilter} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}

