import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import {INSTITUCION} from "../../../redux/constants/action-type";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

class Page extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
     
     goBack(){
         this.props.history.goBack();
     }

    guardar=(dato)=> {
        this.props.actualizarInstitucionWSAction(INSTITUCION,dato);
    }

    render(){
        let opcion=this.props.tipoSeleccion;
        const inputProps = {
            step: 300,
            resize:"2rem",
            fontSize:"2rem",
            style: {fontSize: 15} 

          };

        return (
            <form onSubmit={this.props.handleSubmit} style={{margin: 1, width: '25ch'}}> 
                <Dialog
                    open={this.props.modalEditar}
                   // onClose={this.props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen
                    onBackdropClick={this.hola}
                    disableBackdropClick={true}
                    >
                        <AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.goBack} aria-label="close">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                {<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Editar </Typography>}
                            </Typography>
                            <IconButton onClick={this.props.abrirConfirmacion}>
                                <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                            <IconButton >
                            </IconButton>
                            <IconButton edge="start" color="inherit" onClick={this.props.handleSubmit} aria-label="close">
                                <DoneIcon  style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        </AppBar>

                        <DialogTitle id="form-dialog-title">Actualice los datos del registro.</DialogTitle>


							<DialogContent>
							<TextField
								error={this.props.touched.nombre&&this.props.errors.nombre? true:false}
								helperText={this.props.touched.nombre&&this.props.errors.nombre?this.props.errors.nombre:""}
								size="medium"
								margin="normal"
								id="nombre"
								label="nombre:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.nombre}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.descripcion&&this.props.errors.descripcion? true:false}
								helperText={this.props.touched.descripcion&&this.props.errors.descripcion?this.props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="descripcion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.descripcion}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.direccion&&this.props.errors.direccion? true:false}
								helperText={this.props.touched.direccion&&this.props.errors.direccion?this.props.errors.direccion:""}
								size="medium"
								margin="normal"
								id="direccion"
								label="direccion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.direccion}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.razonSocial&&this.props.errors.razonSocial? true:false}
								helperText={this.props.touched.razonSocial&&this.props.errors.razonSocial?this.props.errors.razonSocial:""}
								size="medium"
								margin="normal"
								id="razonSocial"
								label="razonSocial:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.razonSocial}
								onBlur={this.props.handleBlur}
							/>
                        </DialogContent>                          
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={this.props.modalConfirmacion}   
                                            cerrarConfirmacion={this.props.cerrarConfirmacion}
                                                eliminar={this.props.eliminar} 
                                                    payload={this.props.payload}/>
                    </form>   
                )
    }
}
export default  withRouter(Page);
