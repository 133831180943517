import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CITA,CAT_ESTADO_CITA,RECORDATORIO,CAT_TIPO_CITA,PACIENTE,MEDICO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {eliminarCitaWSAction} from "../../../redux/actions/CitaActions";


const validationSchema = yup.object({
		MEDICO_id: yup.string().required("Campo requerido"),
		PACIENTE_id: yup.string().required("Campo requerido"),
		CAT_ESTADO_CITA_id: yup.string().required("Campo requerido"),
		CAT_TIPO_CITA_id: yup.string().required("Campo requerido"),
		fecha: yup.string().required("Campo requerido"),
		horaInicio: yup.string().required("Campo requerido"),
		horaFin: yup.string().required("Campo requerido"),
		RECORDATORIO_id: yup.string().required("Campo requerido")
})

class CitaFormEdit extends Component{

    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarCitaWSAction(CITA,dato);
    }

    
    render(){    
    
        let valores=this.props.payload;
        let id=this.props.payload.id;
        const { bindSubmitForm } = this.props;

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarCitaWSAction(CITA,values));
                         this.props.history.goBack();
                      }}
                >
                {props => < Page {...props}  medico={this.props.medico}  paciente={this.props.paciente}  catEstadosCita={this.props.catEstadosCita}  catTipoCita={this.props.catTipoCita}  recordatorios={this.props.recordatorios}   modalEditar={this.props.modalEditar} tipoSeleccion={this.props.tipoSeleccion} 
                                                cerrarModalEditar={this.props.cerrarModalEditar} 
                                                eliminar={this.eliminar} 
                                                payload={this.props.payload}
                                                actualizarCitaWSAction={this.props.actualizarCitaWSAction}  
                                                modalConfirmacion={this.state.modalConfirmacion}
                                                cerrarConfirmacion={this.cerrarConfirmacion}
                                                abrirConfirmacion={this.abrirConfirmacion} />}
                </Formik> 
    }



}

const mapStateToProps=(state)=>({
  medico:state.MedicoReducer[MEDICO],
  paciente:state.PacienteReducer[PACIENTE],
  catEstadosCita:state.CatEstadoCitaReducer[CAT_ESTADO_CITA],
  catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA],
  recordatorios:state.RecordatorioReducer[RECORDATORIO],
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        eliminarCitaWSAction: (type,loading) => dispatch(eliminarCitaWSAction(type,loading)),       
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CitaFormEdit));

