import React,{Component} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';


export default class Page extends Component{


    render(){

      let errorsForm=this.props.errorsForm;
      let loading=this.props.loading;


        return (<Card variant="outlined" >
                        <form onSubmit={this.props.handleSubmit}> 
                        <CardHeader        
                          title="Iniciar sesión"
                          subheader="Ingrese su usuario y contraseña"
                        /> 

                    {errorsForm && <Alert severity="error">{errorsForm}</Alert>}

                      <CardContent >
                      
                      {loading && <div className="container-fluid" align="center"><CircularProgress color="secondary" /> </div> }

                        <TextField
                            error={this.props.touched.usuario&&this.props.errors.usuario? true:false}
                            helperText={this.props.touched.usuario&&this.props.errors.usuario?this.props.errors.usuario:""}
                            autoFocus
                            margin="normal"
                            id="usuario"
                            label="usuario:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={this.props.handleChange} value={this.props.values.usuario}
                            onBlur={this.props.handleBlur}
                        />
                        <TextField
                            error={this.props.touched.password&&this.props.errors.password? true:false}
                            helperText={this.props.touched.password&&this.props.errors.password?this.props.errors.password:""}
                            margin="normal"
                            id="password"
                            label="password:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            type="password"
                            onChange={this.props.handleChange} value={this.props.values.password}
                            onBlur={this.props.handleBlur}
                        />
                        
                        </CardContent>
                            <CardActions>

                            <Button onClick={this.props.handleSubmit} variant="contained" color="secondary" disabled={loading} type="submit">
                                Ingresar
                            </Button>
                            </CardActions>


                        </form>   
                    </Card>
                )
    }
}
