import React,{Component} from "react";
import CitaList from "../CitaList";
import CitaBusqueda from "../CitaBusqueda";
import CitaForm from "../CitaForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import CitaButtonBar from "../CitaFloatButton";
import CitaFormEdit from "../CitaFormEdit";
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';


export default class Page extends Component{

    render(){
        let loading=this.props.loading;
        var resultDatos;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Fab position="fixed" variant="round" size="small" aria-label="cargando" style={{position: 'fixed', top: "5.5rem", float: "left",
                                    left: "45%", boxShadow:"1", backgroundColor:"#FFFFFF",padding:"0.1rem"}}>
                                    <CircularProgress color="secondary" size="1.5rem"/>           
                                </Fab>
        }else{
            animacionCargando=<div/>
        }
        
        if(this.props.citaes.length>0){
            resultDatos =  <div className="col-12" >                       
                                <CitaBusqueda buscar={this.props.buscar}  />   
 
                                <CitaList  citaes={this.props.citaes} abrirModalEditar={this.props.abrirModalEditar}
                                                    busqueda={this.props.busqueda}/>
                            </div>
                                
        }


        return <div style={{marginBottom:"4rem" , marginTop:"4rem" }}>
                                 
                    <CitaForm  modal={this.props.modal} 
                                      tipoSeleccion={this.props.tipoSeleccion}
                                        cerrarModal={this.props.cerrarModal} 
                                            agregarCitaWSAction={this.props.agregarCitaWSAction}/>                
                               
                    <CitaFormEdit  modalEditar={this.props.modalEditar} 
                                          payload={this.props.payload} 
                                          tipoSeleccion={this.props.tipoSeleccion}
                                            cerrarModalEditar={this.props.cerrarModalEditar} 
                                                actualizarCitaWSAction={this.props.actualizarCitaWSAction}
                                                eliminarCitaWSAction={this.props.eliminarCitaWSAction}    />                


                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               

                    <div className="row">
                        <CitaButtonBar abrirModal={this.props.abrirModal} />
                    </div>                
                    

                </div>
         
    }
}
