import React,{Component} from "react";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import TextField from "@material-ui/core/TextField";

import DiaSemanaList from "../DiaSemanaList"
import { BorderColor } from "@material-ui/icons";
import { blueGrey } from "@material-ui/core/colors";
import "./estilo.css"

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 12,
    spacing: 8,
    borderWidth:2,
    BorderColor:blueGrey

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
});

export default class Page extends Component{

  constructor(props){
    super(props);    
  }

  handleChange=(e)=>{
		var value = e.target.value;
		this.props.setFechaBusqueda(value);
		console.log("value(fechaBusqueda):"+value);
	}
    render(){    
      
      return<Card  	variant="outlined" style={{borderColor:"dodgerblue"}} >
              <CardContent>
              <h4 className="text_titulo">FECHA DE BUSQUEDA DE HORARIOS DISPONIBLES</h4>                                              

              <TextField
								size="medium"
								margin="normal"
								id="fechaBusqueda"
								label="FECHA DE BUSQUEDA"
								type="date"
								fullWidth
                variant="outlined"
                style={{borderColor:"dodgerblue"}}
								//onChange={this.props.handleChange}
								onChange={this.handleChange} 
								value={this.props.fechaBusqueda}
								onBlur={this.props.handleBlur}
								InputLabelProps={{
									shrink: true,
								  }}								
							/>
              

              <DiaSemanaList    
                                 codColor={this.props.codColor}
                                 duracionTipoCita={this.props.duracionTipoCita}

                                 fecha={this.props.fecha} 
                                 fechaBusqueda={this.props.fechaBusqueda} 
                                 setFieldValue={this.props.setFieldValue} 
                                 horaInicio={this.props.horaInicio} 
                                 horaFin={this.props.horaFin}
                                 idEsquemaTipoCita={this.props.idEsquemaTipoCita}  
                                />


              </CardContent>
            </Card>        
        
    }                          
}
