import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { PERIODO } from "../../../redux/constants/action-type";
import {obtenerPeriodoListWSAction} from "../../../redux/actions/PeriodoActions";

class HorariosChipListItemForm extends Component{
   
    render(){ 

        return <Page 
                    periodo={this.props.periodo}
                    />
    }
   
    componentDidMount(){
        this.props.obtenerPeriodoListWSAction(PERIODO,false);

    }
}
/*UTILIZA LAS PROPIEDADES PASADAS */
const mapStateToProps=(state,ownProps)=>({
    periodo:state.PeriodoReducer[PERIODO].filter(item => item.id === ownProps.horariosChipItem.PERIODO_id)

})

const mapDispatchToProps=(dispatch)=>({
    obtenerPeriodoListWSAction: (type,loading) => dispatch(obtenerPeriodoListWSAction(type,loading)),

})

export default connect(mapStateToProps,mapDispatchToProps)(HorariosChipListItemForm);


