import React,{Component} from "react";
import Page from "./page";
import { PACIENTE } from "../../../redux/constants/action-type";

class PacientesListItem extends Component{
    render(){    
        return< Page paciente={this.props.paciente}  abrirModalEditar={this.props.abrirModalEditar}/>
    }

}

export default PacientesListItem;