import React,{Component} from "react";
import Pages from "./page";

 class GlucosaPDFFloatButton extends Component{
    render(){    
        return <Pages />
    }

}


export default GlucosaPDFFloatButton;