import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {PACIENTE} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {CAT_SEXO} from "../../../redux/constants/action-type";


const validationSchema = yup.object({
	//expediente: yup.string().required("Campo requerido"),
		nombres: yup.string().required("Campo requerido"),
		apellidoPaterno: yup.string().required("Campo requerido"),
		apellidoMaterno: yup.string().required("Campo requerido"),
		SEXO_id: yup.string().required("Campo requerido"),
	//	fechaNacimiento: yup.string().required("Campo requerido"),
		edad: yup.string().required("Campo requerido"),
	//	calle: yup.string().required("Campo requerido"),
	//	ciudad: yup.string().required("Campo requerido"),
	//	estado: yup.string().required("Campo requerido"),
	//	pais: yup.string().required("Campo requerido"),
	//	telefono: yup.string().required("Campo requerido"),
	//	celular: yup.string().required("Campo requerido"),
	//	correo: yup.string().required("Campo requerido")
})

class PacienteForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }
    render(){    
    
        return <Formik

                    initialValues={{
		'expediente': '',
		'nombres': '',
		'apellidoPaterno': '',
		'apellidoMaterno': '',
		'fechaNacimiento': '',
		'edad': '',
		'calle': '',
		'ciudad': '',
		'estado': '',
		'pais': '',
		'telefono': '',
		'celular': '',
		'correo': '',
		'SEXO_id': ''

                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
						  this.goBack();
                          setSubmitting(this.props.agregarPacienteWSAction(PACIENTE,values));
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});

                      }}
                >
					{props => < Page {...props}  
									modal={this.props.modal}
									cerrarModal={this.props.cerrarModal}
									catSexo={this.props.catSexo}
						 		/>}
                </Formik> 
    }
}

const mapStateToProps=(state)=>({
	catSexo:state.CatSexoReducer[CAT_SEXO],
  
  })
  
  export default withRouter(connect(mapStateToProps)(PacienteForm));

