import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
import Select, { Option, ReactSelectProps } from '@material-ui/core/Select'

import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Icon from '@material-ui/core/Icon';

import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class Page extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }

    goBack(){
        this.props.history.goBack();
    }

	handleChange=(e)=>{
	console.log("e.target.selectedOptions:"+e.target.selectedOptions);
	console.log("e.target.selectedOptions:"+e.target.value);
	this.props.setFieldValue(
		"COLOR_id",e.target.value);
	/*
	this.props.setFieldValue(
	  "COLOR_id",
	  [].slice
		.call(e.target.selectedOptions)
		.map(option => option.value)
	)*/
	
	  }
	  
    render(){
        return (<form onSubmit={this.props.handleSubmit}> 
                    <Dialog
                    open={this.props.modal}
                    onClose={this.props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen

                    >
                    <AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.goBack} aria-label="close">
                            <ArrowBackIcon color="primary" style={{  borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} color="primary">
                                {<Typography type="Roboto" color="primary" style={{flexGrow:"1", fontSize:'1.2rem',fontWeight:'normal',textTransform: "" }}>Nuevo tipo de Cita</Typography>}
                            </Typography>

							<Button variant="contained"  edge="start" color="primary" onClick= {this.props.handleSubmit}>Aceptar</Button>

                        </Toolbar>
                        <Divider/>
                        </AppBar>

                        <DialogTitle id="form-dialog-title">Agregar Hospital</DialogTitle>
                        


							<DialogContent>
							<TextField
								error={this.props.touched.nombre&&this.props.errors.nombre? true:false}
								helperText={this.props.touched.nombre&&this.props.errors.nombre?this.props.errors.nombre:""}
								size="medium"
								margin="normal"
								id="nombre"
								label="Nombre de la Cita:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.nombre}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.descripcion&&this.props.errors.descripcion? true:false}
								helperText={this.props.touched.descripcion&&this.props.errors.descripcion?this.props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="Descripcion de la Cita:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.descripcion}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.duracion&&this.props.errors.duracion? true:false}
								helperText={this.props.touched.duracion&&this.props.errors.duracion?this.props.errors.duracion:""}
								size="medium"
								margin="normal"
								id="duracion"
								label="Duracion en minutos de la Cita:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.duracion}
								onBlur={this.props.handleBlur}
							/>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple"> Color asignado a la Cita:</InputLabel>
								<Select
									error={this.props.touched.COLOR_id&&this.props.errors.COLOR_id? true:false}
									size="medium"
									id="COLOR_id"
									label="COLOR_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.handleChange} 
									value={this.props.values.COLOR_id}

								>							
									{this.props.color.map((color)=> (
										<MenuItem key={color.id} value={color.id} >
										 <FiberManualRecordIcon style={{color:color.color,marginRight:"1rem"}}/>
										 <ListItemText primary={color.color} />
										</MenuItem>
									))}
								</Select>

							</FormControl>
                            </DialogContent>
                                         
                    </Dialog>
                    </form>
                       
                )
    }
}
export default  withRouter(Page);
