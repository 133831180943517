import {PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,PRODUCTO,PROVEEDOR,
                    STATE_START_EDITING,STATE_END_EDITING,STATE_START_CREATING,INICIA_CREACION_PACIENTE,
                        FINALIZA_CREACION_PACIENTE,
                            AUTENTICACION_LOGIN,INICIA_LOGIN,FINALIZA_LOGIN,LOGIN_EXITO,
                                MOSTRAR_MENSAJE, MOSTRAR_MENSAJE_TUTORIAL} from "../constants/action-type";


export const startEditingProduct=(product)=>({
    type:STATE_START_EDITING,
    dataTypes:PRODUCTO,
    payload:product
})

export const startEditingSupplier=(supplier) =>({
    type:STATE_START_EDITING,
    dataTypes:PROVEEDOR,
    payload:supplier
})

export const endEditing =()=>({
    type:STATE_END_EDITING
})

export const startCreatingProduct=()=>({
    type:STATE_START_CREATING,
    dataTypes:PRODUCTO
})

export const startCreatingSupplier=()=>({
    type:STATE_START_CREATING,
    dataTypes:PROVEEDOR
})

/*  */
export const solicitandoPeticionWS=()=>({
    type:PETICION_WS_SOLICITANDO
})


export const exitosaPeticionWS=(dataType,registros)=>({
    type:PETICION_WS_EXITOSA,
    registros:registros,
    dataType:dataType
})


export const errorPeticionWS=()=>({
    type:PETICION_WS_ERROR
})


export const iniciaCreacionPaciente=()=>({
    type:INICIA_CREACION_PACIENTE
})

export const finalizaCreacionPaciente=()=>({
    type:FINALIZA_CREACION_PACIENTE
})
/* login */
export const autenticacionLogin=(payload)=>({
    type:AUTENTICACION_LOGIN,
    payload:payload
})

export const iniciaLogin=()=>({
    type:INICIA_LOGIN
})

export const finalizaLogin=()=>({
    type:FINALIZA_LOGIN
})

export const exitoLogin=()=>({
    type:LOGIN_EXITO
})

/* Mensaje Notificaion */

export const mostrarMensaje=(abrirMensaje,mensaje)=>({
    type:MOSTRAR_MENSAJE,
    mensaje:mensaje,
    abrirMensaje:abrirMensaje
})

/* Mensaje Notificaion Tutorial*/

export const mostrarMensajeTutorial=(abrirMensaje,mensaje)=>({
    type:MOSTRAR_MENSAJE_TUTORIAL,
    mensaje:mensaje,
    abrirMensaje:abrirMensaje
})


