import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {ESQUEMA_TIPO_CITA, ESQUEMA_TRABAJO,MEDICO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { ContactlessOutlined } from "@material-ui/icons";
import {obtenerEsquemaTipoCitaListWSAction} from "../../../redux/actions/EsquemaTipoCitaActions";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido"),
		descripcion: yup.string().required("Campo requerido"),
		MEDICO_id: yup.string().required("Campo requerido")
})

class EsquemaTrabajoFormEdit extends Component{

    constructor(props) {
        super(props);
       //Estado se crea para guardar el esquema, tipoDeCitas y horarios que se enviaran a WebServices
        this.state={
                modalConfirmacion:false,
                esquema:{},
                esquemaTipoCitaHorarioPeriodo:[]
              //  horarios:[],
             //   periodos:[],

        };

    }
    goBack(){
        this.props.history.goBack();
    }
    
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }
	
	setEsquema=(valores)=>{
        this.setState({
            esquema: valores
          });
        
    }
    
     
    setEsquemaTipoCitaHorario=(valor)=>{
        this.setState({
            esquemaTipoCitaHorario:this.state.esquemaTipoCitaHorario.concat(valor)
        });
    }

    setHorarios=(valor)=>{
        this.setState({
            horarios:this.state.horarios.concat(valor)

        });
    }

    
    setEsquemaTipoCitaHorarioPeriodo=(valor)=>{
        this.setState({
            esquemaTipoCitaHorarioPeriodo:this.state.esquemaTipoCitaHorarioPeriodo.concat(valor)

        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarEsquemaTrabajoWSAction(ESQUEMA_TRABAJO,dato);
    }

    
    render(){    
     	let idMedico="";
        if(this.props.MedicoUsuario!==undefined){
            idMedico=this.props.MedicoUsuario.MEDICO_id;
        }

        if(this.props.esquemaTipoCita!==undefined){
            console.log("TRAE esquemaTipoCita: "+JSON.stringify(this.props.esquemaTipoCita));
         //   this.setEsquemaTipoDeCita(this.props.esquemaTipoCita); 
        }
            
        //OBTIENE VALORES DEL REGISTRO SELECCIONADO DE LA LISTA PRINCIPAL (valores payloas:{"id":"1","nombre":"ENDOSCOPIAS CLINICA PARTICULAR","descripcion":"Estudio de endoscopia","MEDICO_id":"1"})
        let valores=this.props.payload;
        let id=this.props.payload.id;
        const { bindSubmitForm } = this.props;
        console.log("valores payloas:"+JSON.stringify(valores));

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores EDIT"+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarEsquemaTrabajoWSAction(ESQUEMA_TRABAJO,values));
                         this.props.history.goBack();
                      }}
                >
                {props => < Page {...props}     medico={this.props.medico}   
                                                modalEditar={this.props.modalEditar} 
                                                tipoSeleccion={this.props.tipoSeleccion} 
                                                cerrarModalEditar={this.props.cerrarModalEditar} 
                                                eliminar={this.eliminar} 
                                                payload={this.props.payload}
                                                actualizarEsquemaTrabajoWSAction={this.props.actualizarEsquemaTrabajoWSAction}  
                                                modalConfirmacion={this.state.modalConfirmacion}
                                                cerrarConfirmacion={this.cerrarConfirmacion}
                                                abrirConfirmacion={this.abrirConfirmacion} 
                                                setEsquemaTipoDeCita={this.setEsquemaTipoDeCita}
                                                esquemaTipoCitaHorarioPeriodo={this.state.esquemaTipoCitaHorarioPeriodo} 
                                                setEsquemaTipoCitaHorarioPeriodo={this.setEsquemaTipoCitaHorarioPeriodo} 
                                                />
                                                
                                                }
                </Formik> 
    }

    //(15-Nov-20) SE ACTUALIZA EL STATE (tiposDeCitas) CONSULTANDO LOS TIPOS DE CITAS POR ESQUEMA DE TRABAJO
    componentDidMount(){ 
      //  this.setTipoDeCita({"id":"1","ESQUEMA_TRABAJO_id":"1","CAT_TIPO_CITA_id":"1"});
      //  this.setEsquemaTipoDeCita({"id":"1","ESQUEMA_TRABAJO_id":"1","CAT_TIPO_CITA_id":"1"}); //SELECT ESQUEMA_TIPO_DE_CITA WHERE ESQUEMA_TRABAJO_id=1
     ////   this.setEsquemaTipoDeCita({"id":"1"}); 
      //  this.setEsquemaTipoCitaHorario({"ESQUEMA_TIPO_CITA_id":"1","HORARIO_id":"1"}); // SELECT EsquemaTipoCitaHorario WHERE ESQUEMA_TIPO_CITA_id=consultaAnterior      
        //this.setHorarios({"idDia":"1","dia":"LUNES","desde":"09:00","hasta":"12:00","idTipoCita":"1"});
     //   this.setHorarios({"id":"1","DIA_SEMANA_id":"6","PERIODO_id":"1"});
    //    this.setPeriodos({"id":"1","horaInicial":"09:00","horaFinal":"12:00"});

        /*CREAR UNA FUNCION QUE LLENE EL ESTADO DEL EQUEMA PARA QUE PUEDA SER PROCESADO POR LA APLICACION*/
    ////    this.setEsquemaTipoCitaHorarioPeriodo([
    ////    {"CAT_TIPO_CITA_id":"1","DIA_SEMANA_id":"6","horaInicial":"09:00","horaFinal":"12:00"},
   ////     {"CAT_TIPO_CITA_id":"1","DIA_SEMANA_id":"5","horaInicial":"09:00","horaFinal":"12:00"}]);

    ////    this.props.obtenerEsquemaTipoCitaListWSAction(ESQUEMA_TIPO_CITA,false); /* Invoca por primara vez a productos (default) */	


    }

    componentDidUpdate(){
        console.log("componentDidUpdate(¿¿¿):"+JSON.stringify(this.state));
        
    }

}
const mapDispatchToProps = (dispatch, ownProps) => ({
    	obtenerEsquemaTipoCitaListWSAction: (type,loading) => dispatch(obtenerEsquemaTipoCitaListWSAction(type,loading)),
        
    })
const mapStateToProps=(state)=>({
  medico:state.MedicoReducer[MEDICO],
  esquemaTipoCita:state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA],

})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EsquemaTrabajoFormEdit));

