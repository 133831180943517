import React,{Component} from "react";
import Page from "./page";
import { connect } from "react-redux";
import { ESQUEMA_TIPO_CITA_HORARIO, PERIODO, HORARIO } from "../../../redux/constants/action-type";
import {obtenerPeriodoListWSAction} from "../../../redux/actions/PeriodoActions";
import {obtenerHorarioListWSAction} from "../../../redux/actions/HorarioActions";
import {obtenerEsquemaTipocitaHorarioListWSAction} from "../../../redux/actions/EsquemaTipoCitaHorarioActions";

class CitasDisponiblesCard extends Component{
    constructor(props){
        super(props);
        this.state={
			fechaBusqueda: new Date().toISOString().substring(0,10),   /* 07Ene21 obtienen la fecha de hoy en que se realiza la busqueda de horarios disponibles por default */
        };
	 }
	 

	setFechaBusqueda=(valor)=>{
        this.setState({
            fechaBusqueda:valor
        })
    } 
	 

    render(){
		return <Page periodo={this.props.periodo} 
					 horario={this.props.horario} 
					 esquemaTipoCitaHorario={this.props.esquemaTipoCitaHorario} /* Obtiene los horarios (DIA DE LA SEMANA y PERIODO) del esquemaTipoCita disponibles para este id */
					 
					 codColor={this.props.codColor} 
					 duracionTipoCita={this.props.duracionTipoCita}
					 
					 fecha={this.props.fecha} 
					 fechaBusqueda={this.state.fechaBusqueda} 
					 setFechaBusqueda={this.setFechaBusqueda} 
					 setFieldValue={this.props.setFieldValue} 
					 horaInicio={this.props.horaInicio} 
					 horaFin={this.props.horaFin}
					 idEsquemaTipoCita={this.props.idEsquemaTipoCita}  

									/>
    }
	componentDidMount(){            
			this.props.obtenerPeriodoListWSAction(PERIODO,false); /* Invoca por primara vez a productos (default) */	
			this.props.obtenerHorarioListWSAction(HORARIO,false); /* Invoca por primara vez a productos (default) */	
			this.props.obtenerEsquemaTipocitaHorarioListWSAction(ESQUEMA_TIPO_CITA_HORARIO,false); /* Invoca por primara vez a productos (default) */	
	}
}

const mapStateToProps =(state,ownProps)=>({
	periodo: state.PeriodoReducer[PERIODO],
	horario:state.HorarioReducer[HORARIO],
	esquemaTipoCitaHorario:state.EsquemaTipoCitaHorarioReducer[ESQUEMA_TIPO_CITA_HORARIO].filter(e => e.ESQUEMA_TIPO_CITA_id === ownProps.idEsquemaTipoCita)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	obtenerPeriodoListWSAction: (type,loading) => dispatch(obtenerPeriodoListWSAction(type,loading)),
	obtenerHorarioListWSAction: (type,loading) => dispatch(obtenerHorarioListWSAction(type,loading)),
	obtenerEsquemaTipocitaHorarioListWSAction: (type,loading) => dispatch(obtenerEsquemaTipocitaHorarioListWSAction(type,loading))
	
})

export default connect(mapStateToProps,mapDispatchToProps)(CitasDisponiblesCard);

