import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { CAT_TIPO_CITA,ESQUEMA_TIPO_CITA } from "../../../redux/constants/action-type";
import {obtenerCatTipoCitaListWSAction,agregarCatTipoCitaWSAction,actualizarCatTipoCitaWSAction,eliminarCatTipoCitaWSAction} from "../../../redux/actions/CatTipoCitaActions";
import {obtenerEsquemaTipoCitaListWSAction} from "../../../redux/actions/EsquemaTipoCitaActions";

class TiposDeCitasForm extends Component{
    constructor(props) {
        super(props);
        this.state={
                openMenu:false,
                anchorEl:null,
        };
    }
    
    abrirMenu=()=> {
        this.setState({
            openMenu:true
        });
    }
    cerrarMenu=()=> {
        this.setState({
            openMenu:false
        });
    }

    setAnchorEl=(value)=> {
        this.setState({
            anchorEl:value
        });
    }
    setHorario(valor){
        this.setState(state => ({
            horarios: state.horarios.concat(valor)
          }));
    }

    render(){    
        return <Page openMenu={this.state.openMenu}  
                    cerrarMenu={this.cerrarMenu}  
                    abrirMenu={this.abrirMenu}
                    anchorEl={this.state.anchorEl}  
                    setAnchorEl={this.setAnchorEl} 
                    cat_tipo_cita={this.props.cat_tipo_cita}
                    esquemaTipoCita={this.props.esquemaTipoCita}
                    ESQUEMA_TRABAJO_id={this.props.ESQUEMA_TRABAJO_id}
                    />
    }

    componentDidMount(){
        this.props.obtenerCatTipoCitaListWSAction(CAT_TIPO_CITA,false);
        this.props.obtenerEsquemaTipoCitaListWSAction(ESQUEMA_TIPO_CITA,false);

    }

}

const mapStateToProps=(state)=>({
    cat_tipo_cita:state.CatTipoCitaReducer[CAT_TIPO_CITA],
    esquemaTipoCita:state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA],

})

const mapDispatchToProps=(dispatch)=>({
    obtenerCatTipoCitaListWSAction: (type,loading) => dispatch(obtenerCatTipoCitaListWSAction(type,loading)),
    obtenerEsquemaTipoCitaListWSAction: (type,loading) => dispatch(obtenerEsquemaTipoCitaListWSAction(type,loading)),

})

export default connect(mapStateToProps,mapDispatchToProps)(TiposDeCitasForm);