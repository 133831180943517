import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/PersonRounded';
import {Link} from "react-router-dom";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import {MASCULINO,FEMENINO} from "../../../utils/constantes";
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
export default class Page extends Component{

    render(){
        
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e"];
        let paciente=this.props.paciente;

        let edad="";
        if(this.props.paciente!==undefined){
          edad=this.props.paciente.edad+"a";
        }
        let fechaNac="";
        if(this.props.paciente!==undefined){
          fechaNac=this.props.paciente.fechaNacimiento;
        }

        let colorFace="#90a4ae";
        if(this.props.paciente!==undefined){          
          console.log("this.props.paciente.sexo:"+this.props.paciente.sexo);
          if(this.props.paciente.SEXO_id===MASCULINO){
            colorFace="#64b5f6";
          }else if(this.props.paciente.SEXO_id===FEMENINO){
            colorFace="#f06292";
          }  
        }
        
        return  <div>
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload: paciente  
                  }
                  }} >
                    <ListItemAvatar>
						         <SupervisorAccountOutlinedIcon style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem" ,color:"gray"}} alt={paciente.expediente} />
                    </ListItemAvatar>
                    <ListItemText id={paciente.id} 
                      primary={<Typography type="Roboto" style={{  color:colorFace,fontSize:'1rem',fontWeight:'normal' }}>{paciente.nombres} {paciente.apellidoPaterno} {paciente.apellidoMaterno}</Typography>}
								      secondary={<Typography type="Roboto" style={{color: '#6c757d', fontSize:'0.9rem',fontWeight:'normal' }}>({fechaNac})</Typography>} />
                    <ListItemSecondaryAction >
                        <Badge badgeContent={edad} color='primary' />                      
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="inset" component="li" />

                </div>
    }
}
