import React,{Component} from "react";
import PacientesListItem from "../CitaListItem";
import List from '@material-ui/core/List';
import CitasDisponiblesCard from "../CitasDisponiblesCard";
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import "./estilo.css";

export default class Page extends Component{

  constructor(props) {
    super(props);
  }
  
  handleClick=()=>{
 
    let dia=("0" + this.props.dia).slice(-2);
    let idMes=("0" + (this.props.idMes + 1)).slice(-2);
    let anio=this.props.anio;
    let fecha=anio+"-"+idMes+"-"+dia;
    this.props.setFecha(fecha);
    this.props.setHoraInicio(this.props.desde);
    this.props.setHoraFin(this.props.hasta);
 
  }
  

  render(){
    //horaInicio={this.props.horaInicio} horaFin={this.props.horaFin}
    //07Ene21 se agrega action para identificar que el card selecionado sea el correcto 
      let backgroundColorLocal=((this.props.horaInicio===this.props.desde)&&this.props.action)?"rgb(2, 136, 209)":this.props.codColorItem;
      let color=((this.props.horaInicio===this.props.desde)&&this.props.action)?"white":"black";
      let icon=((this.props.horaInicio===this.props.desde)&&this.props.action)?<CheckCircleOutlineIcon style={{color:"white"}} />:<QueryBuilderIcon  color="primary" />;
      let border=((this.props.horaInicio===this.props.desde)&&this.props.action)?"rgb(2, 136, 209)":this.props.codColor;

             return <Grid className="muiChip-default" >
                      <Chip 
                        //   avatar={<Avatar>M</Avatar>}
                            size="large"
                            icon={icon}
                            label={this.props.desde+" - "+this.props.hasta}
                            clickable                    
                            disabled={this.props.contenido}
                            onClick={this.handleClick} 
                          // onDelete={handleDelete}
                          //  deleteIcon={<DoneIcon />}
                            variant="outlined"                          
                            style={{backgroundColor:backgroundColorLocal,color:color,borderColor:this.props.ajustado?"gold":"silver",borderStyle:this.props.ajustado?"groove":"solid",borderWidth:this.props.ajustado?"0.2rem":"0.09rem"}}
                            className="chip_horario_seleccionado"
                          />
                    </Grid>
                      
                      
    }                          
}
