import React,{Component} from "react";
import Page from "./page";

export default class PacientesList extends Component{

    render(){
        let pacienteesFilter = this.props.pacientees.filter((data)=>{
			if(data!=undefined){

			if(data.nombres!=undefined){
				if(this.props.busqueda == null){
					return data;
				}else if(data.nombres.toLowerCase().includes(this.props.busqueda.toLowerCase())){
					return data;
				}
			}}
         });   
  
        return< Page pacienteesFilter={pacienteesFilter} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}

