import {RestDataSource} from "./RestDataSource";
import {CONSULTAR_USUARIOS,AGREGAR_USUARIO,ACTUALIZAR_USUARIO, ELIMINAR_USUARIO} from "../constants/Usuario-action-type";
import {AGREGAR_USUARIO_ROL} from "../constants/UsuarioRol-action-type";
import {AGREGAR_MEDICO_USUARIO} from "../constants/MedicoUsuario-action-type";
import {ID_ROL_MEDICO, ID_ROL_PACIENTE} from "../../utils/constantes"
import {AGREGAR_PACIENTE_USUARIO} from "../constants/PacienteUsuario-action-type";


import {USUARIO,USUARIO_ROL,
        MOSTRAR_MENSAJE,
        PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
        TIPO_DE_DATO,
        ESTADO,MEDICO_USUARIO,PACIENTE_USUARIO
        } from "../constants/action-type";
import setAuthorizationToken from "../../utils/setAuthorizationToken";
import jwt from "jsonwebtoken";
import { CONSULTAR_ESTADOS } from "../constants/Estado-action-type";
import { isEqual } from "date-fns";


/* middleware pasa como parameto 2 Url para realizar la consulta antes de enviar la llamada a los reducers
y regresa una funcion
*/

export const createUsuarioMiddleware =(usuariosURL,usuarioRolURL,medicoUsuarioURL,pacienteUsuarioURL,estadosURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [USUARIO]: new RestDataSource(usuariosURL),
        [USUARIO_ROL]: new RestDataSource(usuarioRolURL),
        [MEDICO_USUARIO]: new RestDataSource(medicoUsuarioURL),
        [PACIENTE_USUARIO]: new RestDataSource(pacienteUsuarioURL),
        [ESTADO]: new RestDataSource(estadosURL),
    }

/* Realiza el dispacht dependiendo de la accion  */
    return ({dispath,getState})=>next=>action =>{

        console.log("Dentro de createUsuarioMiddleware:"+action.type);

        /* Antes de enviar al Reducer */
        switch(action.type){

  /** USUARIO INICIO */
                case CONSULTAR_USUARIOS:
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                    dataSources[action.dataType].GetData((data,mensaje)=>{ 
                        if(data===null){
                            if(action.loading){next({type:PETICION_WS_ERROR});}
                        }else{
                        next({     //por cada registro devuelto del Webservices se envia la funcion next
                            type:CONSULTAR_USUARIOS,
                            dataType:action.dataType,
                            payload:data}); 
                            if(action.loading){
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:data.length});
                            }
                        }
                        }
                    );             
                break;   
 
                case AGREGAR_USUARIO:
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].SetData((data,mensaje)=>{ 
                        if(data===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("AGREGAR_USUARIO (data):"+JSON.stringify(data));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_USUARIO,
                                dataType:action.dataType,
                                payload:data});

                            //AGREGA ID DE USUARIO AGRAGADO AL PAYLOAD PARA SEGUNDA CONSULTA
                            var datosUsuarioRol ={};
                            datosUsuarioRol["idusuario"]=data.id;
                            datosUsuarioRol["idRol"]=action.payload.rol.CAT_ROL_id;
        
                            console.log("datosUsuarioRol+"+JSON.stringify(action.payload.rol));    
                            console.log("datosUsuarioRol ok+"+JSON.stringify(datosUsuarioRol));    

                            dataSources[USUARIO_ROL].SetData((dataRol,mensaje)=>{ 
                                if(dataRol===null){
                                    console.log("FALLA PETICION A USUARIO_ROL:"+mensaje);
                                    next({type:PETICION_WS_EXITOSA});
                                }else{
                                    console.log("AGREGAR_USUARIO_ROL (data):"+JSON.stringify(dataRol));
                
                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                        type:AGREGAR_USUARIO_ROL,
                                        dataType:USUARIO_ROL,
                                        payload:dataRol});
                                        
                                    /// AGREGA ID MEDICO_USARIO PARA ID ===1 CORRSPONDE ROL_MEDICO
                                    if(datosUsuarioRol.idRol===ID_ROL_MEDICO){
                                        var datosMedicoUsuario ={};
                                        datosMedicoUsuario["MEDICO_id"]=action.payload.rol.MEDICO_id;
                                        datosMedicoUsuario["USUARIO_id"]=data.id;

                                        console.log("MEDICO USARIO "+JSON.stringify(datosMedicoUsuario));

                                        dataSources[MEDICO_USUARIO].SetData((dataMedicoUsuario,mensaje)=>{ 
                                            if(dataMedicoUsuario===null){
                                                console.log("FALLA PETICION A MEDICO_USUARIO:"+mensaje);
                                                next({type:PETICION_WS_ERROR});
                                            }else{
                                                console.log("AGREGAR_MEDICO_USUARIO (data):"+JSON.stringify(dataMedicoUsuario));
                            
                                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                    type:AGREGAR_MEDICO_USUARIO,
                                                    dataType:MEDICO_USUARIO,
                                                    payload:dataMedicoUsuario});
                                                                                        
                                            }
                                        },datosMedicoUsuario);
                                    }else if(datosUsuarioRol.idRol===ID_ROL_PACIENTE){ //(21Feb21Dom.) Se agrega caso para el perfil USUARIO
                                        var datosPacienteUsuario ={};
                                        datosPacienteUsuario["PACIENTE_id"]=action.payload.rol.PACIENTE_id;
                                        datosPacienteUsuario["USUARIO_id"]=data.id;

                                        console.log("PACIENTE USARIO "+JSON.stringify(datosPacienteUsuario));

                                        dataSources[PACIENTE_USUARIO].SetData((dataPacienteUsuario,mensaje)=>{ 
                                            if(dataPacienteUsuario===null){
                                                console.log("FALLA PETICION A PACIENTE_USUARIO:"+mensaje);
                                                next({type:PETICION_WS_ERROR});
                                            }else{
                                                console.log("PACIENTE_USUARIO (data):"+JSON.stringify(dataPacienteUsuario));
                            
                                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                    type:AGREGAR_PACIENTE_USUARIO,
                                                    dataType:PACIENTE_USUARIO,
                                                    payload:dataPacienteUsuario});
                                                                                        
                                            }
                                        },datosPacienteUsuario);

                                    }
        
                                    let count=parseInt( getState().estadosReducer.registros) + 1;
                                    console.log("coun:"+count); 
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                                }
                            },datosUsuarioRol);
                          }
                        },action.payload.usuario);
        
                    break;                     

                    /** ESTADO INICIO */

                                    
                    case CONSULTAR_ESTADOS:
                        if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                        dataSources[action.dataType].GetData((data,mensaje)=>{ 
                            if(data===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_ESTADOS,
                                dataType:action.dataType,
                                payload:data}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:data.length});
                                }
                            }
                            }
                        );             
                    break;   


            default:
                    next(action);

        }
        

    }

}