import React,{Component} from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';

import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import Grid from '@material-ui/core/Grid';
import HorariosChipListForm from "../HorariosChipListForm";

export default class Page extends Component{

   
    render(){

        let horariosChipForm="";
        if(this.props.esquemaTipoCitaHorario!==undefined){
            horariosChipForm=this.props.esquemaTipoCitaHorario.map(esquemaTipoCitaHorarioItem=> 
              <HorariosChipListForm
                         esquemaTipoCitaHorarioItem={esquemaTipoCitaHorarioItem} 
                         dia_semanaItem={this.props.dia_semanaItem}
                         />  
              );
        }


/*  SE INSERTA LOS OBJETOS PARA SER ENVIADO AL WS */
        const handleChange = (valor) => (event, isExpanded) => { /*  SE INSERTA LOS OBJETOS PARA SER ENVIADO AL WS */
            this.props.setOpenPeriodoDialog(true);
            var horario ={};
            horario["DIA_SEMANA_id"]=valor.id;
            this.props.setHorario(horario);
            this.props.setEsquemaTipoCitaHorario(this.props.esquemaTipoCitaItem);
          };

            return  <div>
                        <Card variant="elevation" elevation="2" >
                            <CardHeader                 
                            title={<Typography>{this.props.dia_semanaItem.dia}</Typography>}
                            action={<QueryBuilderIcon  fontSize="large" color="secondary" onClick={handleChange(this.props.dia_semanaItem)}/>}
                            /> 
                            <CardContent>
                            <Typography component={'span'} align="justify" display="inline" >
                                <Grid container spacing={1}>
                                    {horariosChipForm}
                                </Grid>
                            </Typography>
                            </CardContent>
                        </Card>                        
                    </div>
    }
}

