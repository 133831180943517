import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {INSTITUCION} from "../../../redux/constants/action-type";
import {obtenerInstitucionListWSAction,agregarInstitucionWSAction,actualizarInstitucionWSAction,eliminarInstitucionWSAction} from "../../../redux/actions/InstitucionActions";


class Instituciones extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'nombre': '',
					'descripcion': '',
					'direccion': '',
					'razonSocial': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

     cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }


    render(){        
        return  <Page instituciones={this.props.instituciones} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarInstitucionWSAction={this.props.agregarInstitucionWSAction}
                                actualizarInstitucionWSAction={this.props.actualizarInstitucionWSAction}
                                eliminarInstitucionWSAction={this.props.eliminarInstitucionWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}/>    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
                this.props.obtenerInstitucionListWSAction(INSTITUCION,true); /* Invoca por primara vez a productos (default) */
            }else{
                this.setState(this.props.location.valores);
            }

        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    instituciones:state.InstitucionReducer[INSTITUCION],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerInstitucionListWSAction: (type,loading) => dispatch(obtenerInstitucionListWSAction(type,loading)),
        agregarInstitucionWSAction: (type,payload) => dispatch(agregarInstitucionWSAction(type,payload)),
        actualizarInstitucionWSAction: (type,payload) => dispatch(actualizarInstitucionWSAction(type,payload)),
        eliminarInstitucionWSAction: (type,payload) => dispatch(eliminarInstitucionWSAction(type,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Instituciones);


