import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, MEDICO, PERSONA, ESQUEMA_TRABAJO, ESQUEMA_TIPO_CITA,PERIODO, HORARIO, ESQUEMA_TIPO_CITA_HORARIO,MOSTRAR_MENSAJE_TUTORIAL} from "../constants/action-type";
import { CONSULTAR_MEDICOS, AGREGAR_MEDICO, ACTUALIZAR_MEDICO, ELIMINAR_MEDICO } from "../constants/Medico-action-type";
import { CONSULTAR_ESQUEMA_TRABAJOS,AGREGAR_ESQUEMA_TRABAJO } from "../constants/EsquemaTrabajo-action-type";
import { insert } from "formik";
import { AGREGAR_ESQUEMA_TIPO_CITA } from "../constants/EsquemaTipoCita-action-type";
import { AGREGAR_PERIODO } from "../constants/Periodo-action-type";
import { AGREGAR_HORARIO } from "../constants/Horario-action-type";
import { AGREGAR_ESQUEMA_TIPOCITA_HORARIO } from "../constants/EsquemaTipocitaHorario-action-type";
import {PROFIL_MEDICO} from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createEsquemaTrabajoMiddleware =(esquemaTrabajoURL,esquemaTipoCitaURL,periodoURL,horarioURL,esquemaTipoCitaHorarioURL,medicosURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [ESQUEMA_TRABAJO]: new RestDataSource(esquemaTrabajoURL),
        [ESQUEMA_TIPO_CITA]: new RestDataSource(esquemaTipoCitaURL),
        [PERIODO]: new RestDataSource(periodoURL),
        [HORARIO]: new RestDataSource(horarioURL),
        [ESQUEMA_TIPO_CITA_HORARIO]: new RestDataSource(esquemaTipoCitaHorarioURL),
        [MEDICO]: new RestDataSource(medicosURL)
    }


return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** MEDICO INICIO */                                    
                case CONSULTAR_ESQUEMA_TRABAJOS:
//26Oct2020 Se actualiza para realizar consulta para el perfil MEDICO
                 let profil=getState().autenticacionReducer.profil;
                    console.log("PERFIL DEL USUARIO:"+profil);
                    if(profil===PROFIL_MEDICO){ //SE REALIZA LA BUSQUEDA DE LAS CITAS DEL MEDICO POR SU ID.
                        var datosIdByMedico ={};
                        datosIdByMedico['MEDICO_id']=getState().MedicoUsuarioReducer.medicoUsuario[0].MEDICO_id;
                        console.log("datosIdByMedico:"+JSON.stringify(datosIdByMedico));
                        console.log("datosIdByMedico(action.loading):"+action.loading);

                        if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                        dataSources[action.dataType].GetDataParams((dataEsquema,mensaje)=>{ 
                            if(dataEsquema===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_ESQUEMA_TRABAJOS,
                                dataType:action.dataType,
                                payload:dataEsquema}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataEsquema.length});
                                }
                               /* if(dataEsquema.length===0){
                                    next({type:MOSTRAR_MENSAJE_TUTORIAL,abrirMensaje:true,mensaje:"Agregar Esquema de trabajo requerido"});                             

                                }*/
                            }
                        },datosIdByMedico);
    
                    }else{

                    }

            
                break;   

                case AGREGAR_ESQUEMA_TRABAJO:
                    next({type:PETICION_WS_SOLICITANDO});
                    console.log("AGREGAR_ESQUEMA_TRABAJO:(payload)"+JSON.stringify(action.payload.esquema));
                                        /*Se agrega esquema de trabajo action.payload.esquema se regresa un ID*/    

                    dataSources[action.dataType].SetData((dataEsquema,mensaje)=>{ 
                        if(dataEsquema===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se actuliza en Axios (dataEsquema):"+JSON.stringify(dataEsquema));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_ESQUEMA_TRABAJO,
                                dataType:action.dataType,
                                payload:dataEsquema});
                           /*****/
                        
                        
                            /*** FIN **/
                                let count=parseInt( getState().estadosReducer.registros) + 1;

                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 

                           /*****/
                            }
                        },action.payload.esquema);            
                             
                 break; 

        default:
                next(action);

    }
}

}

export default createEsquemaTrabajoMiddleware;