import React,{Component} from "react";
import Page from "./page";
import { connect } from "react-redux";
import { ESQUEMA_TRABAJO, ESQUEMA_TIPO_CITA, COLOR } from "../../../redux/constants/action-type";
import {obtenerEsquemaTrabajoListWSAction} from "../../../redux/actions/EsquemaTrabajoActions";
import {obtenerEsquemaTipoCitaListWSAction} from "../../../redux/actions/EsquemaTipoCitaActions";
import {obtenerColorListWSAction} from "../../../redux/actions/ColorActions";

class CitasDisponibles extends Component{

    render(){
		
		console.log("fechaDefault:"+this.props.fecha);
		console.log("fechaDefault:"+this.props.fechaDefault);
		console.log("idTipoCita:"+this.props.idTipoCita);
		console.log("esquemaTipoCita(CitasDisponibles):"+JSON.stringify(this.props.esquemaTipoCita));
		console.log("color:"+JSON.stringify(this.props.color));
		console.log("catTipoCita:"+JSON.stringify(this.props.catTipoCita));
		let catTipoCita_=this.props.catTipoCita.filter(c=>c.id===this.props.idTipoCita)[0];
		console.log("colores:"+JSON.stringify(catTipoCita_));
		let codColor="";
		let duracionTipoCita="00:00:00";
		if(this.props.CAT_TIPO_CITA_id!==""){
			duracionTipoCita=this.props.catTipoCita.filter(item=>item.id===this.props.CAT_TIPO_CITA_id)[0].duracion;
		 }
		console.log("duracionTipoCita(this.props.CAT_TIPO_CITA_id):"+this.props.CAT_TIPO_CITA_id);
		
		if(catTipoCita_!== undefined){
			let colorDisponible=this.props.color.filter(e => e.id ===catTipoCita_.COLOR_id)[0];
			if(colorDisponible!==undefined){
				codColor=colorDisponible.color;
				console.log("codColor:"+JSON.stringify(codColor));
	
			}
				
		}

		return <Page esquema={this.props.esquemaMedico} 

//					 esquemaTipoCita={this.props.esquemaTipoCita} 
					 codColor={codColor} 
					 duracionTipoCita={duracionTipoCita} 

					 fecha={this.props.fecha} 
					 setFieldValue={this.props.setFieldValue} 
					 horaInicio={this.props.horaInicio} 
					 horaFin={this.props.horaFin}
					 esquemaTipoCitaSelecionado={this.props.esquemaTipoCitaSelecionado}

						
		/>
    }
	componentDidMount(){            
//			this.props.obtenerEsquemaTipoCitaListWSAction(ESQUEMA_TIPO_CITA,false); /* Invoca por primara vez a productos (default) */	
	}
}


const mapStateToProps =(state,ownProps)=>({
//	esquemaTipoCita:state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA].filter(e => e.CAT_TIPO_CITA_id === ownProps.idTipoCita),
	color:state.ColorReducer[COLOR]
})

const mapDispatchToProps = (dispatch, ownProps) => ({
//	obtenerEsquemaTipoCitaListWSAction: (type,loading) => dispatch(obtenerEsquemaTipoCitaListWSAction(type,loading)),
	
})

export default connect(mapStateToProps,mapDispatchToProps)(CitasDisponibles);

