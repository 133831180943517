import {PRODUCTO,PROVEEDOR,CONSULTAR,UPDATE,DELETE} from "../constants/action-type";

let idCounter=100;

/*
saveProduct define una funcion que al ser llamada regre una accion con el type, payload (opcinal) parametros 
adicionlaes que son pasados en los reducers
*/
export const saveProduct =(product)=>{
    return createSaveEvent(PRODUCTO,product);
}

export const saveSupplier=(supplier) =>{
    return createSaveEvent(PROVEEDOR,supplier);
}

/**estos action creator tienen logica de negocio, normalmente son objetos java con el nombre de la action 
 *  const action = { type: 'MARK_FAVORITE' };
 * y deben de llevar solo un parametro obligatorio type donde se describe tipo de accion al reducer
 * dataType (aqui se refiera al tipo de dato PRODUCTO O PROVEEDOR) y payload son parametros que recibe la Accion 
 * y es enviada al Reducer. 
 */
const createSaveEvent=(dataType,payload)=>{
    if(!payload.id){
        return{
            type:CONSULTAR,
/* estos parametros adicionales en la accion son datos adicionales que son utilizados o pasados como argumento
 al reducer { type: 'STORE', dataType:dataType, ... } */
            dataType:dataType,
            payload:{...payload,id:idCounter++}
        }
    }else{
        return{
            type:UPDATE,
            dataType:dataType,
            payload:payload

        }
    }
}




export const deleteProduct =(product)=>({
    type:DELETE,
    dataType:PRODUCTO,
    payload:product.id

})

export const deleteSupplier =(supplier) =>({
    type:DELETE,
    dataType:PROVEEDOR,
    payload:supplier.id


})


/* Action creator? */
export const obtenerDatosWS = (dataType)=> {
    return {
        type:CONSULTAR,
        dataType:dataType
    }
}




