import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {logout} from "../../redux/actions/autenticacionActions"
import requireProfil from "../../utils/requireProfil";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {Link} from "react-router-dom";


class ListItemComponent extends Component{
    render(){        
        return <ListItem button selected={this.props.menu===this.props.opcion} component={Link} to={this.props.ruta}  onClick={this.props.handleDrawerToggleClose}>                                    
                    <ListItemIcon>{this.props.icon}</ListItemIcon>
                    <ListItemText  primary={<Typography type="Roboto" variant="subtitle1" style={{ color: "#000000",fontSize:"1.2rem",fontWeight:"normal" }}>{this.props.nombreMenu}</Typography>} />
                </ListItem>
    }
}


export default requireProfil(ListItemComponent);
