import React,{Component} from "react";
import Page from "./page";
import { connect } from "react-redux";
import { ESQUEMA_TIPO_CITA_HORARIO, PERIODO, HORARIO } from "../../../redux/constants/action-type";
import {obtenerPeriodoListWSAction} from "../../../redux/actions/PeriodoActions";
import {obtenerHorarioListWSAction} from "../../../redux/actions/HorarioActions";
import {obtenerEsquemaTipocitaHorarioListWSAction} from "../../../redux/actions/EsquemaTipoCitaHorarioActions";


class DiaSemanaList extends Component{


    getFechaDeBusqueda=()=>{
        let newDate=  new Date(this.props.fechaBusqueda+" 00:00:00");
            
          /* fecha de busqueda fechaBusqueda={this.props.fechaBusqueda} */
 /*         newDate.setFullYear(this.props.fechaBusqueda.split("-")[0]);
          newDate.setMonth(this.props.fechaBusqueda.split("-")[1]-1);
          newDate.setDate(this.props.fechaBusqueda.split("-")[2]);
*/
          console.log("this.props.diaBusquedaSelecionado (fechaBusqueda) :"+this.props.fechaBusqueda);

          return newDate;
        
    }

    getPeriodoPorDia=()=>{
        let periodoPorDia=[];
        //01Ene21 esquemaTipoCitaHorario obtiene la coleccion de los dias de semana y horarios disponobles para el tipo de cita  para el tipo de cita seleccionado por el usuario.
        console.log("esquemaTipoCitaHorario:::"+JSON.stringify(this.props.esquemaTipoCitaHorario));

        this.props.esquemaTipoCitaHorario.map(item=>
            this.props.horario.filter(f=>f.id===item.HORARIO_id ).map(h=>
              this.props.periodo.filter(f=>f.id===h.PERIODO_id).map(p=>{
                console.log("hDIA_SEMANA_id:"+JSON.stringify(h));
                periodoPorDia.push({"DIA_SEMANA_id":h.DIA_SEMANA_id,"horaInicial":p.horaInicial,"horaFinal":p.horaFinal});
               }
              )
            )
          );
          
        return periodoPorDia;
    }

    getPeriodoPorDiaOrdenado=()=>{
        let periodoPorDia=this.getPeriodoPorDia();                  
        console.log("periodoPorDia:::"+JSON.stringify(periodoPorDia));
        console.log("periodoPorDia:::"+periodoPorDia.length);


        let agrupacionPeriodosDisponibles = periodoPorDia.reduce((recursivo, item) => {
        recursivo[item.DIA_SEMANA_id] = [...recursivo[item.DIA_SEMANA_id] || [], item];
          return recursivo;
         }, {});


   
        /*for(var i=1;i<8;i++){
          if(periodoPorDia.length!==0){

            if(agrupacionPeriodosDisponibles[diaDeBusqueda]!==undefined){
              console.log("diaDeBusqueda:"+ diaDeBusqueda);
              agrupacionPeriodosDisponiblesOrdenado[6]=agrupacionPeriodosDisponibles[diaDeBusqueda];
            }

          }
          diaDeBusqueda++
          if(diaDeBusqueda==8){
            diaDeBusqueda=1;
          }    
        }*/
  
    
          /* Se recorren los dias de la semana 7 comenzando por el dia actual para obtener el siguiente dia proximo en la consulta  */
 /*         for(var i=1;i<8;i++){
            if(periodoPorDia.length!==0){
              let resultado = periodoPorDia.filter( e => e.DIA_SEMANA_id === diaDeBusqueda.toString());

              if(resultado.length!==0){
                //Ordena en el nuevo arreglo
                periodoPorDiaOrdenado.push(resultado[0]);
                console.log("periodoPorDia(resultado):::"+JSON.stringify(resultado[0]));
                console.log("periodoPorDia(resultado.length):::"+resultado.length);

              }            
            }
            diaDeBusqueda++
            if(diaDeBusqueda==8){
              diaDeBusqueda=1;
            }        
          }
  */
      /*    var weekday = new Array(1,2,3,4,5,6,7);
          weekday.forEach(item=>{
            console.log("item(item):::"+item);
            if(agrupacionPeriodosDisponibles[item]!==undefined){
              agrupacionPeriodosDisponibles[item].map(i=>{
                console.log("agrupacionPeriodosDisponibles(i):::"+JSON.stringify(i));

              });

            }

            }
          );*/

          return agrupacionPeriodosDisponibles;
     //   return periodoPorDia;

  
    }

    //16Ene21 Ordena los dias de la semana iniciando por el mas cercano al dia actual. los dias de la semana coresponde L-1 ... D-7
    getWeekSorte(day){
      let dayInitial=day;
      let weekday = [];
      for(var item=0;item<7;item++){
       // weekday.push(dayInitial);
       console.log("dayInitial"+dayInitial);
        weekday[item] = dayInitial;
        dayInitial++;
        if(dayInitial==8){
          dayInitial=1;
        }
      }
      
      return weekday;

    }
    render(){
        let periodoPorDiaOrdenado=this.getPeriodoPorDiaOrdenado();
        let newDate =this.getFechaDeBusqueda(); 
        console.log("periodoPorDiaOrdenado 0>>"+JSON.stringify(periodoPorDiaOrdenado));
        console.log("newDate 0>>"+newDate);
        let diaDeBusqueda = newDate.getDay();  
        let weekday =this.getWeekSorte(diaDeBusqueda);

        return <Page 
                    periodoPorDiaOrdenado={periodoPorDiaOrdenado}
                    diaBusquedaSelecionado={newDate}
                    weekday={weekday}
                    
                    codColor={this.props.codColor}
                    duracionTipoCita={this.props.duracionTipoCita}

                    fecha={this.props.fecha} 
                    fechaBusqueda={this.props.fechaBusqueda} 
                    setFieldValue={this.props.setFieldValue} 
                    horaInicio={this.props.horaInicio} 
                    horaFin={this.props.horaFin}
                    
        />
    }
	componentDidMount(){            
        this.props.obtenerPeriodoListWSAction(PERIODO,false); /* Invoca por primara vez a productos (default) */	
        this.props.obtenerHorarioListWSAction(HORARIO,false); /* Invoca por primara vez a productos (default) */	
        this.props.obtenerEsquemaTipocitaHorarioListWSAction(ESQUEMA_TIPO_CITA_HORARIO,false); /* Invoca por primara vez a productos (default) */	
    }
}

const mapStateToProps =(state,ownProps)=>({
    periodo: state.PeriodoReducer[PERIODO],
    horario:state.HorarioReducer[HORARIO],
    esquemaTipoCitaHorario:state.EsquemaTipoCitaHorarioReducer[ESQUEMA_TIPO_CITA_HORARIO].filter(e => e.ESQUEMA_TIPO_CITA_id === ownProps.idEsquemaTipoCita)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    obtenerPeriodoListWSAction: (type,loading) => dispatch(obtenerPeriodoListWSAction(type,loading)),
    obtenerHorarioListWSAction: (type,loading) => dispatch(obtenerHorarioListWSAction(type,loading)),
    obtenerEsquemaTipocitaHorarioListWSAction: (type,loading) => dispatch(obtenerEsquemaTipocitaHorarioListWSAction(type,loading))

})

export default connect(mapStateToProps,mapDispatchToProps)(DiaSemanaList);


