import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {MEDICO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		especialidad: yup.string().required("Campo requerido"),
		nombres: yup.string().required("Campo requerido"),
		apellidoPaterno: yup.string().required("Campo requerido"),
		apellidoMaterno: yup.string().required("Campo requerido"),
		cedulaProfesional: yup.string().required("Campo requerido"),
		correo: yup.string().required("Campo requerido"),
		telefono: yup.string().required("Campo requerido"),
		celular: yup.string().required("Campo requerido")
})

class MedicoFormEdit extends Component{

    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarMedicoWSAction(MEDICO,dato); /* Se implementa eliminar de web services y redux */
    }

    
    render(){    
    
        let valores=this.props.payload;
        let id=this.props.payload.id;
        const { bindSubmitForm } = this.props;

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarMedicoWSAction(MEDICO,values)); /* Se implementa actualizar de web services y redux */
                         this.props.history.goBack();
                      }}
                >
                {props => < Page {...props}   modalEditar={this.props.modalEditar} tipoSeleccion={this.props.tipoSeleccion} 
                                                cerrarModalEditar={this.props.cerrarModalEditar} 
                                                eliminar={this.eliminar} 
                                                payload={this.props.payload}
                                                actualizarMedicoWSAction={this.props.actualizarMedicoWSAction}  
                                                modalConfirmacion={this.state.modalConfirmacion}
                                                cerrarConfirmacion={this.cerrarConfirmacion}
                                                abrirConfirmacion={this.abrirConfirmacion} />}
                </Formik> 
    }



}

const mapStateToProps=(state)=>({
})

export default withRouter(connect(mapStateToProps)(MedicoFormEdit));

