import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { CAT_DIA_SEMANA,  HORARIO } from "../../../redux/constants/action-type";

class HorarioChipListForm extends Component{
   
    render(){ 

        console.log("esquemaTipoCitaHorario<:>"+JSON.stringify(this.props.esquemaTipoCitaHorario));   
        return <Page horario={this.props.horario}
                    />
    }
   

}

const mapStateToProps=(state,ownProps)=>({
    horario:state.HorarioReducer[HORARIO].filter(item => item.id === ownProps.esquemaTipoCitaHorarioItem.HORARIO_id).filter(i => i.DIA_SEMANA_id === ownProps.dia_semanaItem.id) 

})

const mapDispatchToProps=(dispatch)=>({

})

export default connect(mapStateToProps,mapDispatchToProps)(HorarioChipListForm);


