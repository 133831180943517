import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CAT_TIPO_CITA,COLOR,MEDICO_USUARIO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido"),
		descripcion: yup.string().required("Campo requerido"),
		duracion: yup.string().required("Campo requerido"),
		COLOR_id: yup.string().required("Campo requerido")
})

class CatTipoCitaForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }
    render(){    
    
        let idMedico="";
        if(this.props.MedicoUsuario!==undefined){
            idMedico=this.props.MedicoUsuario.MEDICO_id;
        }
        console.log("(idMedico) "+idMedico);                              

        return <Formik

                    initialValues={{
                    'nombre': '',
                    'descripcion': '',
                    'duracion': '00:30:00',
                    'COLOR_id': "",
                    'MEDICO_id': idMedico
                    
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                        console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                        setSubmitting(this.props.agregarCatTipoCitaWSAction(CAT_TIPO_CITA,values));
                          resetForm({values:""});
                          this.goBack();

                      }}
                >
                    {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                    }) => < Page setFieldValue={setFieldValue} 
                                 handleSubmit={handleSubmit} 
                                 handleChange={handleChange} 
                                 handleBlur={handleBlur} 
                                 values={values} 
                                 errors={errors} 
                                 touched={touched}                                            
                                 modal={this.props.modal}                                                     
                                 cerrarModal={this.props.cerrarModal}                                                    
                                 color={this.props.color} 
                    />}

                </Formik> 
    }
}

const mapStateToProps=(state)=>({
  color:state.ColorReducer[COLOR],
  MedicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0],

})

export default withRouter(connect(mapStateToProps)(CatTipoCitaForm));


