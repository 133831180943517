import React,{Component} from "react";
import PacienteList from "../PacienteList";
import PacienteBusqueda from "../PacienteBusqueda";
import PacienteForm from "../PacienteForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import PacienteButtonBar from "../PacienteFloatButton";
import PacienteFormEdit from "../PacienteFormEdit";
import Fab from '@material-ui/core/Fab';


export default class Page extends Component{

    render(){
        let loading=this.props.loading;
        var resultDatos;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Fab position="fixed" variant="round" size="small" aria-label="cargando" style={{position: 'fixed', top: "5.5rem", float: "left",
                                    left: "45%", boxShadow:"1", backgroundColor:"#FFFFFF",padding:"0.1rem"}}>
                                    <CircularProgress color="secondary" size="1.5rem"/>           
                                </Fab>
        }else{
            animacionCargando=<div/>
        }
        
        if(this.props.pacientees.length>0){
            resultDatos =  <div className="col-12" >                       
                                <PacienteBusqueda buscar={this.props.buscar}/>                                    
                                <PacienteList  pacientees={this.props.pacientees} abrirModalEditar={this.props.abrirModalEditar}
                                                    busqueda={this.props.busqueda}/> 
                            </div>
                                
        }


        return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                                 
                    <PacienteForm  modal={this.props.modal} 
                                      tipoSeleccion={this.props.tipoSeleccion}
                                        cerrarModal={this.props.cerrarModal} 
                                            agregarPacienteWSAction={this.props.agregarPacienteWSAction}/>                
                               
                    <PacienteFormEdit  modalEditar={this.props.modalEditar} 
                                          payload={this.props.payload} 
                                          tipoSeleccion={this.props.tipoSeleccion}
                                            cerrarModalEditar={this.props.cerrarModalEditar} 
                                                actualizarPacienteWSAction={this.props.actualizarPacienteWSAction}
                                                eliminarPacienteWSAction={this.props.eliminarPacienteWSAction}    />                

                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        <PacienteButtonBar abrirModal={this.props.abrirModal} />
                    </div>                
                    

                </div>
         
    }
}
