import React,{Component} from "react";
import PacientesListItem from "../EsquemaTrabajoListItem";
import List from '@material-ui/core/List';

export default class Page extends Component{

  constructor(props) {
    super(props);
  }

    render(){      
      return <List > {
            this.props.esquemaTrabajosFilter.map(esquemaTrabajo=> 
                      <PacientesListItem esquemaTrabajo={esquemaTrabajo} abrirModalEditar={this.props.abrirModalEditar}/> )
            }</List>
    }                          
}
