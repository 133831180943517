import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {getMesNombreValue,getDiaSemanaNombreValue} from "./date";

let moment = extendMoment(Moment);

export const getFecha=(fecha)=>{
    console.log("fecha: "+fecha);

    let myDate = new Date(fecha+" 00:00:00");

console.log("Date: "+moment(myDate).format("YYYY-MM-DD"));
console.log("Year: "+moment(myDate).format("YYYY"));
console.log("Month: "+moment(myDate).format("MM"));
console.log("Month: "+moment(myDate).format("MMMM"));
console.log("Day: "+moment(myDate).format("DD"));
console.log("Day: "+moment(myDate).isoWeekday());
console.log("Time: "+moment(myDate).format("HH:mm")); // Time in24 hour format
console.log("Time: "+moment(myDate).format("hh:mm A"));
let dia=moment(myDate).format("DD").slice(0,1);
console.log("dia: "+dia);

    return getDiaSemanaNombreValue(parseInt(moment(myDate).isoWeekday()))+" "+moment(myDate).format("DD")+","+getMesNombreValue(parseInt(moment(myDate).format("MM"))-1);
}



//recibe horario 'YYYY-MM-DD HH:mm:ss' y con el fotmato regresa 'HH:mm'
export const getFormatoHora=(horaInicialFormatoString)=>{
    console.log("getFormatoHorario horaInicialFormatoString:"+horaInicialFormatoString);

    let horaInicial = moment(horaInicialFormatoString, 'HH:mm:ss A');

    return horaInicial.format('HH:mm A');

}


//recibe date fecha de nacimineto y regresa la edad
export const getEdad=(fechaNac)=>{

    var a = moment();
    var b = moment(fechaNac, 'MM-YYYY');
    var age = moment.duration(a.diff(b));
    var years = age.years();
    return years;
}

//recibe horario 'YYYY-MM-DD HH:mm:ss' y con el fotmato regresa 'HH:mm'
export const getFormatoFecha=(horaInicialFormatoString)=>{
    console.log("getFormatoHorario horaInicialFormatoString:"+horaInicialFormatoString);

    let horaInicial = moment(horaInicialFormatoString, 'YYYY-MM-DD HH:mm');

    return horaInicial.format('YYYY-MM-DD');

}

//recibe horario 'YYYY-MM-DD HH:mm:ss' y con el fotmato regresa 'HH:mm'
export const getFormatoHorario=(horaInicialFormatoString)=>{
    console.log("getFormatoHorario horaInicialFormatoString:"+horaInicialFormatoString);

    let horaInicial = moment(horaInicialFormatoString, 'YYYY-MM-DD HH:mm:ss');

    return horaInicial.format('HH:mm:ss');

}

//recibe horario 'YYYY-MM-DD HH:mm:ss' le suma los minutos enviados en segundo parametro regresa 'YYYY-MM-DD HH:mm:ss'
export const ajustaHorarioHaciaDelante=(horaInicialFormatoString,minutos)=>{
    
    console.log("horaFinalFormatoString:"+horaInicialFormatoString);
    console.log("minutos:"+minutos);


    let horaFinal = moment(horaInicialFormatoString, 'YYYY-MM-DD HH:mm').add(minutos, 'minutes');

    return horaFinal.format('YYYY-MM-DD HH:mm');

}

export const ajustaHorarioHaciaAtras=(horaFinal,minutos)=>{
    
    console.log("horaFinalFormatoString:"+horaFinal);
    console.log("minutos:"+minutos);


    let horaInicial = moment(horaFinal, 'YYYY-MM-DD HH:mm').subtract(minutos, 'minutes');

    return horaInicial.format('YYYY-MM-DD HH:mm');

}

export const isHorarioDesdeContenido=(horaInicialFormatoString,horaFinalFormatoString,horaItemFormatoString)=>{
    
    console.log("horaInicialFormatoString:"+horaInicialFormatoString);
    console.log("horaFinalFormatoString:"+horaFinalFormatoString);
    console.log("horaItemFormatoString:"+horaItemFormatoString);

    let horaInicial = moment(horaInicialFormatoString, 'YYYY-MM-DD HH:mm');//.add(1, 'minutes');
    let horaFinal = moment(horaFinalFormatoString, 'YYYY-MM-DD HH:mm').subtract(1, 'minutes');
    let horaItem = moment(horaItemFormatoString,'YYYY-MM-DD HH:mm');

    let range3 = moment.range(horaInicial, horaFinal)

    let contenido=range3.contains(horaItem);

    return contenido;

}

export const isHorarioHastaContenido=(horaInicialFormatoString,horaFinalFormatoString,horaItemFormatoString)=>{
    

    console.log("horaInicialFormatoString:"+horaInicialFormatoString);
    console.log("horaFinalFormatoString:"+horaFinalFormatoString);
    console.log("horaItemFormatoString:"+horaItemFormatoString);

    let horaInicial = moment(horaInicialFormatoString, 'YYYY-MM-DD HH:mm').add(1, 'minutes');
    let horaFinal = moment(horaFinalFormatoString, 'YYYY-MM-DD HH:mm');//.subtract(1, 'minutes');
    let horaItem = moment(horaItemFormatoString,'YYYY-MM-DD HH:mm');

    let range3 = moment.range(horaInicial, horaFinal)

    let contenido=range3.contains(horaItem);

    return contenido;

}


    //19Ene21 recibe horario inicial y final de la cita y desde hasta de item y verifica si estos horarios son interceptados
    export const isHorarioIntersect=(horaInicialFormatoString,horaFinalFormatoString,horaItemFormatoString,horaItemFinalFormatoString)=>{

    let result=false;
        console.log("horaInicialFormatoString (isHorarioIntersect):"+horaInicialFormatoString);
        console.log("horaFinalFormatoString (isHorarioIntersect):"+horaFinalFormatoString);
        console.log("horaItemFormatoString (isHorarioIntersect):"+horaItemFormatoString);
        console.log("horaItemFinalFormatoString (isHorarioIntersect):"+horaItemFinalFormatoString);
       
        let horaInicial = moment(horaInicialFormatoString, 'YYYY-MM-DD HH:mm').add(1, 'minutes');
        let horaFinal = moment(horaFinalFormatoString, 'YYYY-MM-DD HH:mm').subtract(1, 'minutes');
        let horaItem = moment(horaItemFormatoString,'YYYY-MM-DD HH:mm');
        let horaItemFinal = moment(horaItemFinalFormatoString,'YYYY-MM-DD HH:mm');

        let range3 = moment.range(horaInicial, horaFinal)
        let range4 = moment.range(horaItem, horaItemFinal)

        let contenido3=range3.intersect(range4)
        if(contenido3!==null){
            result=true;
        }
        console.log("interfec:"+result);
        return result;

    }

//recibe horario 'YYYY-MM-DD HH:mm:ss' le suma los minutos enviados en segundo parametro regresa 'YYYY-MM-DD HH:mm:ss'
export const getFechaHoy=()=>{
    
    let horaInicial = moment(new Date());

    return horaInicial.format('DD.MM.YYYY');

}
