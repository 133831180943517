import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';

import CitasDisponibles from "../CitasDisponibles"
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Grid from "@material-ui/core/Grid"
import Button from '@material-ui/core/Button';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class Page extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }

    goBack(){
        this.props.history.goBack();
    }
/*
	handleChange=(e)=>{
		console.log("e.target.selectedOptions:"+e.target.selectedOptions);
		console.log("e.target.selectedOptions:"+e.target.value);
		console.log("e.target.selectedOptions:"+this.props.catTipoCita[0]);
		this.props.setFieldValue("MEDICO_id",e.target.value);
		this.props.setFieldValue("CAT_TIPO_CITA_id","");
		this.props.setDuracionTipoCita("CAT_TIPO_CITA_id","");
		
	}*/

    render(){
		console.log("MEDICO SELECCIONADO:"+this.props.values.MEDICO_id);
		let esquemaMedico=this.props.esquemaTrabajos.filter(item=>item.MEDICO_id===this.props.values.MEDICO_id)[0];
		let esquemaTipoCitaDisponible=[];
		let catTipoCitaDisponible=[];
		let esquemaTipoCitaSelecionado=[];


		console.log("[esquemaMedico]:"+JSON.stringify(esquemaMedico));


		if(esquemaMedico!==undefined){			
			esquemaTipoCitaDisponible=this.props.esquemaTipoCita.filter(eTipoCitaItem=>eTipoCitaItem.ESQUEMA_TRABAJO_id===esquemaMedico.id);
		}
		/*if()
		 let esquemaMedico=this.props.esquemaTipoCita.filter(eTipoCitaItem=>eTipoCitaItem.ESQUEMA_TRABAJO_id===(
			(this.props.esquemaTrabajos.filter(item=>item.MEDICO_id===this.props.values.MEDICO_id)[0]).id
		));*/

		if(esquemaTipoCitaDisponible.length >0){
		//	this.props.values.CAT_TIPO_CITA_id="";
		catTipoCitaDisponible=this.props.catTipoCita.filter(item=>(esquemaTipoCitaDisponible.filter(tCitaDisponible=>
									tCitaDisponible.CAT_TIPO_CITA_id===item.id)[0]!==undefined));


		}



//07ene21 Filtra el tipo de cita[PANENDO/COLON etc] del medico a partir de su seleecion en el formulario (SIEMPRE VIENE UN ELEMENTO).
		esquemaTipoCitaSelecionado=esquemaTipoCitaDisponible.filter(item=>item.CAT_TIPO_CITA_id===this.props.values.CAT_TIPO_CITA_id)

		console.log("[esquemaTipoCitaSelecionado]:"+JSON.stringify(esquemaTipoCitaSelecionado));
		console.log("esquemaTipoCitaDisponible:"+JSON.stringify(esquemaTipoCitaDisponible));
		console.log("catTipoCitaDisponible:"+JSON.stringify(catTipoCitaDisponible));

/*
		let tipoDeCitasMenu=this.props.cat_tipo_cita.filter(item=>
			(this.props.tiposDeCitas.filter(tcita=>
				  tcita.id===item.id)[0]===undefined
				  ));*/


        return (<form onSubmit={this.props.handleSubmit}> 
                    <Dialog
                    open={this.props.modal}
                    onClose={this.props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen

                    >
						{
							console.log("values Formik:"+JSON.stringify(this.props.values))
						}
						{
							console.log("errors Formik:"+JSON.stringify(this.props.errors))
						}
						
                    <AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.goBack} aria-label="close">
                            <ArrowBackIcon  color="primary" style={{ borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                {<Typography type="Roboto" color="primary" style={{flexGrow:"1", fontSize:'1.3rem',fontWeight:'normal',textTransform: "capitalize" }}>Nueva Cita </Typography>}
                            </Typography>


							<Button variant="contained"  edge="start" color="primary" onClick= {this.props.handleSubmit}>Aceptar</Button>

                        </Toolbar>
                        <Divider/>
                        </AppBar>

                        <DialogTitle id="form-dialog-title">Agregar Cita</DialogTitle>                        
						  <DialogContent>							

							
							<FormControl
							fullWidth
							margin="normal"	
							variant="filled" 
						
							>
								<InputLabel alignItems="center" style={{marginTop:"0",paddingTop:"0",top: "-0.3rem"}}>
								<Grid container    justify="center"   direction="row" alignItems="center">								
								<Grid item>
								<AccountCircle fontSize="normal" />
								</Grid>
								<Grid item>
								PACIENTE
								</Grid>
								</Grid>
							
							   </InputLabel>
								<Select
									error={this.props.touched.PACIENTE_id&&this.props.errors.PACIENTE_id? true:false}
									size="medium"
									id="PACIENTE_id"
									label="PACIENTE_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.PACIENTE_id}
									onBlur={this.props.handleBlur}
									native
								>
									

										<option aria-label="None" value="" disabled/>
						
										{
											this.props.pacientes.map(paciente=> (
												<option key={paciente.id}  value={paciente.id}>{paciente.nombres} {paciente.apellidoPaterno}</option>
											))
									}
								</Select>
							
							</FormControl>

							<FormControl
							fullWidth
							margin="normal"
							variant="filled" 
							>
							    <InputLabel htmlFor="age-native-simple"> TIPO DE CITA</InputLabel>
								<Select
									error={this.props.touched.CAT_TIPO_CITA_id&&this.props.errors.CAT_TIPO_CITA_id? true:false}
									size="medium"
									id="CAT_TIPO_CITA_id"
									label=" TIPO DE CITA"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} 
									value={this.props.values.CAT_TIPO_CITA_id}
									onBlur={this.props.handleBlur}
									native
									InputProps={{
										startAdornment: (
										  <InputAdornment position="start">
											<AssignmentIndIcon />
										  </InputAdornment>
										),
									  }}
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									catTipoCitaDisponible.map(cattipocita=> (
										<option key={cattipocita.id}  value={cattipocita.id}>{cattipocita.nombre}</option>)
									)}
								</Select>
							</FormControl>
							

							<CitasDisponibles  
												CAT_TIPO_CITA_id={this.props.values.CAT_TIPO_CITA_id} //07Ene21 Se requiere para obtener duracion del tipo de cita selecionado por el usuario en el formulario
												catTipoCita={this.props.catTipoCita}  //07Ene21 Se utiliza para seleccion de color del tipo de cita 												
												fecha={this.props.values.fecha}      //07Ene21 Se utiliza para el action de seleccion en Card
												setFieldValue={this.props.setFieldValue} 
											
												horaInicio={this.props.values.horaInicio} 
												horaFin={this.props.values.horaFin}
											
												esquemaMedico={esquemaMedico}
												esquemaTipoCitaSelecionado={esquemaTipoCitaSelecionado}
															 	/>

                            </DialogContent>
                                         
                    </Dialog>

                    </form>
                       
                )
    }
}
export default  withRouter(Page);
