
export const getMesNombre=(dateResult)=>{
    const meses = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.",
    "Jul.", "Ago.", "Sep.", "Oct.", "Nov.", "Dic."];
    return meses[dateResult.getMonth()];

 }

 export const getMesNombreValue=(dateResult)=>{
    const meses = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.",
    "Jul.", "Ago.", "Sep.", "Oct.", "Nov.", "Dic."];
    return meses[dateResult];

 }
 
 
 export const getDiaSemanaNombre=(dateResult)=>{
    const dias = ["dom.","Lun.", "Mar.", "Mie.", "Jue.", "Vie.", "Sab."];
    return dias[dateResult.getDay()];
 }

 export const getDiaSemanaNombreValue=(dateResult)=>{
    const dias = ["dom.","Lun.", "Mar.", "Mie.", "Jue.", "Vie.", "Sab.","dom."];
    return dias[dateResult];
 }


 export const getFormatoFechaDisponible=(diaSem,dia,mes,anio)=>{
     return diaSem+" "+dia +", "+mes+" "+anio;
 }

 // Recibe fecha y hora y regresa con el formato YYYY-MM-DD HH:mm:ss
 export const getFechaHoraString=(fecha,hora)=>{
     console.log("< no hora:"+hora);
     let horaSplit=hora.split(":");
    let fechaHora=fecha+" "+horaSplit[0]+":"+horaSplit[1];
    console.log("< no fechaHora:"+fechaHora);

    return fechaHora;
}

//Recibe un Date y regresa el fromato YYYY-MM-DD HH:mm
export const getFormatoDesdeDate=(inicioDate)=>{
    let fechaFormato= inicioDate.toISOString().split('T')[0]+" "+inicioDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
    return fechaFormato;
}

export const getFechaHoyFormato=()=>{
    let date = new Date()

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    let fecha="";
    if(month < 10){
        fecha=`${day}/0${month}/${year}`;
    }else{
        fecha=`${day}/${month}/${year}`;
    }

    return fecha;
}

export const getFormatoFechaCita=(diaSem,dia,mes,anio)=>{
    let formatoFecha="";
    let date = new Date()
    let day = date.getDate()
    let month = getMesNombre(date);
    let year = date.getFullYear()

    console.log("day"+day);
    console.log("dia"+dia);

    console.log("month"+month);
    console.log("mes"+mes);
    
    console.log("year"+year);
    console.log("anio"+anio);

    if(day===dia&&month===mes &&year===anio){
        formatoFecha="Hoy";
    }else{
        formatoFecha=diaSem+" "+dia +", "+mes;
    }
    return formatoFecha;
}

