import React,{Component} from "react";
import CitaListItem from "../CitaListItem";
import List from '@material-ui/core/List';

export default class Page extends Component{

  constructor(props) {
    super(props);    
    }
    

    render(){   
      let fecha="";// new Date().toISOString().substring(0,10);

      /*let mostrarFechaEncabezado=true;
      let citaList="";

      this.props.citaesFilter.map(cita=>{
        if(fecha!==cita.fecha){
          console.log("state(diferente):"+cita.fecha);        
          console.log("fecha(diferente):"+fecha);        
          fecha=cita.fecha;
          mostrarFechaEncabezado=true;
        }else{
          mostrarFechaEncabezado=false;
          console.log("fecha(igual):"+fecha);        
        }

        citaList=<CitaListItem cita={cita} abrirModalEditar={this.props.abrirModalEditar} mostrarFecha={mostrarFechaEncabezado}/> 
  
      });*/

      return <List>{this.props.citaesFilter.map(cita=>{
               let mostrarFechaEncabezado=true;
               console.log("fecha:"+fecha)
               console.log("cita.fecha:"+cita.fecha)
                if(fecha!==cita.fecha){
                  fecha=cita.fecha;
                  mostrarFechaEncabezado=true;
                }else
                  mostrarFechaEncabezado=false;
                
              return <CitaListItem cita={cita} abrirModalEditar={this.props.abrirModalEditar} mostrarFecha={mostrarFechaEncabezado}/> 
                  }                      
                    )}
            </List>
    }                          
}
