import React,{Component} from "react";
import Page from "./page";

export default class MedicoList extends Component{

    render(){
        let medicosFilter = this.props.medicos.filter((data)=>{
			if(data.especialidad!=undefined){
				if(this.props.busqueda == null){
					return data;
				}else if(data.especialidad.toLowerCase().includes(this.props.busqueda.toLowerCase())){
					return data;
				}
			}
         });   
  
        return< Page medicosFilter={medicosFilter} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}

