import React,{Component} from "react";
import Grid from '@material-ui/core/Grid';
import DiaSemanaListItem from "../DiaSemanaListItem";
import "./estilo.css"


export default class Page extends Component{

 

  render(){

    
    console.log("this.props.periodoPorDiaOrdenado:"+JSON.stringify(this.props.periodoPorDiaOrdenado));
    console.log("weekday:"+JSON.stringify(this.props.weekday));
       
    return <Grid direction="row"
                  justify="center"
                  alignItems="center" spacing={1} style={{flexGrow:"1"}}>   
                      <h4 className="text_titulo">HORARIOS EN 7 DIAS </h4>                                              
              {
              // Se obtinene los dias de la semana y los periodos de horario desde el esquema de trabajo {this.props.setPeriodoPorDia({p.horaInicial+","+p.horaInicial+","+p.horaInicial})}
              this.props.weekday.map(item=>{
                                    if(this.props.periodoPorDiaOrdenado[item]!==undefined){
                                      return  <DiaSemanaListItem  
                                                idDia={item}           
                                                periodoList={this.props.periodoPorDiaOrdenado[item]}

                                                diaBusquedaSelecionado={this.props.diaBusquedaSelecionado}

                                                duracionTipoCita={this.props.duracionTipoCita}
                            
                                                fecha={this.props.fecha} 
                                                setFieldValue={this.props.setFieldValue} 
                                                horaInicio={this.props.horaInicio} 

                                                />   
                                    }                  
                                  }                  
                            )
              }              
          </Grid>
                    
                      
    }                          
}

/*

                periodoPorDiaOrdenado.map(item=>
                  <DiaSemanaListItem  
                                      horaInicial={item.horaInicial} 
                                      horaFinal={item.horaFinal} 
                                      fecha={this.props.fecha}
                                      idDia={item.DIA_SEMANA_id} 
                                      diaBusquedaSelecionado={this.props.diaBusquedaSelecionado}
                                      duracionTipoCita={this.props.duracionTipoCita}
                                      setFieldValue={this.props.setFieldValue} 
                                      horaInicio={this.props.horaInicio} 

                                      />           
                )          

          */
