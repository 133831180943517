import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/PersonRounded';
import ListItemText from '@material-ui/core/ListItemText';


import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import "./estilo.css";
import {getEdad} from "../../../utils/moment"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class Page extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }

    goBack(){
        this.props.history.goBack();
    }


	handleFechaDeNacimiento=(e)=>{
		this.props.handleChange(e);
		let edad=getEdad(new Date(e.target.value));
		if(edad<150){
			this.props.setFieldValue("edad",edad);	
		}
	}

	handleChange=(e)=>{
		this.props.setFieldValue("SEXO_id",e.target.value);			  
	}

    render(){
        return (<form onSubmit={this.props.handleSubmit}> 
                    <Dialog
                    open={this.props.modal}
                    onClose={this.props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen

                    >
                    <AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.goBack} aria-label="close">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                {<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nuevo Paciente</Typography>}
                            </Typography>

                            <IconButton edge="start" color="inherit" onClick={this.props.handleSubmit} aria-label="close">
                                <DoneIcon  style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        </AppBar>

                        <DialogTitle id="form-dialog-title">Agregar Hospital</DialogTitle>
                        


							<DialogContent>
								<Box color="text.primary" className="datosGeneralesTop">
									<Box color="text.primary" className="datosGeneralesTopAvatar">

										<Avatar style={{width:50,height:50}}>
										<PersonIcon  />
										</Avatar>
									</Box>	
									<Box color="text.primary" >
										<TextField
											error={this.props.touched.nombres&&this.props.errors.nombres? true:false}
											helperText={this.props.touched.nombres&&this.props.errors.nombres?this.props.errors.nombres:""}
											size="medium"
											margin="normal"
											id="nombres"
											label="Nombres:"
											type="text"
											fullWidth
											variant="outlined"
											onChange={this.props.handleChange} value={this.props.values.nombres}
											onBlur={this.props.handleBlur}
											
										/>
										<TextField
											error={this.props.touched.apellidoPaterno&&this.props.errors.apellidoPaterno? true:false}
											helperText={this.props.touched.apellidoPaterno&&this.props.errors.apellidoPaterno?this.props.errors.apellidoPaterno:""}
											size="medium"
											margin="normal"
											id="apellidoPaterno"
											label="Apellido Paterno:"
											type="text"
											fullWidth
											variant="outlined"
											onChange={this.props.handleChange} value={this.props.values.apellidoPaterno}
											onBlur={this.props.handleBlur}
										/>
										<TextField
											error={this.props.touched.apellidoMaterno&&this.props.errors.apellidoMaterno? true:false}
											helperText={this.props.touched.apellidoMaterno&&this.props.errors.apellidoMaterno?this.props.errors.apellidoMaterno:""}
											size="medium"
											margin="normal"
											id="apellidoMaterno"
											label="Apellido Materno:"
											type="text"
											fullWidth
											variant="outlined"
											onChange={this.props.handleChange} value={this.props.values.apellidoMaterno}
											onBlur={this.props.handleBlur}
										/>
									</Box>

								</Box>
									

								<FormControl
								fullWidth
								variant="filled" 								
								>
									<InputLabel id="demo-simple-select-filled-label">SEXO:</InputLabel>

									<Select
										error={this.props.touched.SEXO_id&&this.props.errors.SEXO_id? true:false}
										size="medium"
										id="SEXO_id"
										label="SEXO:"
										type="text"
										fullWidth
										variant="outlined"
										onChange={this.handleChange} 
										value={this.props.values.SEXO_id}
									>					
								
										{this.props.catSexo.map((sexo)=> (
											<MenuItem key={sexo.id} value={sexo.id} id={sexo.id}>
												<ListItemText primary={sexo.descripcion} />
											</MenuItem>
										))}
									</Select>

								</FormControl>

							<TextField
								error={this.props.touched.fechaNacimiento&&this.props.errors.fechaNacimiento? true:false}
								helperText={this.props.touched.fechaNacimiento&&this.props.errors.fechaNacimiento?this.props.errors.fechaNacimiento:""}
								size="medium"
								margin="normal"
								id="fechaNacimiento"
								label="Fecha Nacimiento:"
								defaultValue="1980-05-24"
								type="date"
								fullWidth
								variant="outlined"
								onChange={this.handleFechaDeNacimiento}
								value={this.props.values.fechaNacimiento}
								onBlur={this.props.handleBlur}
								InputLabelProps={{
									shrink: true,
								  }}
							/>
							<TextField
								error={this.props.touched.edad&&this.props.errors.edad? true:false}
								helperText={this.props.touched.edad&&this.props.errors.edad?this.props.errors.edad:""}
								size="medium"
								margin="normal"
								id="edad"
								label="Edad:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.edad}
								onBlur={this.props.handleBlur}
							/>	
							<TextField
								error={this.props.touched.expediente&&this.props.errors.expediente? true:false}
								helperText={this.props.touched.expediente&&this.props.errors.expediente?this.props.errors.expediente:""}
								size="medium"
								margin="normal"
								id="expediente"
								label="Expediente:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.expediente}
								onBlur={this.props.handleBlur}
							/>
							
							
							
							<TextField
								error={this.props.touched.calle&&this.props.errors.calle? true:false}
								helperText={this.props.touched.calle&&this.props.errors.calle?this.props.errors.calle:""}
								size="medium"
								margin="normal"
								id="calle"
								label="Calle:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.calle}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.ciudad&&this.props.errors.ciudad? true:false}
								helperText={this.props.touched.ciudad&&this.props.errors.ciudad?this.props.errors.ciudad:""}
								size="medium"
								margin="normal"
								id="ciudad"
								label="Ciudad:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.ciudad}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.estado&&this.props.errors.estado? true:false}
								helperText={this.props.touched.estado&&this.props.errors.estado?this.props.errors.estado:""}
								size="medium"
								margin="normal"
								id="estado"
								label="Estado:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.estado}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.pais&&this.props.errors.pais? true:false}
								helperText={this.props.touched.pais&&this.props.errors.pais?this.props.errors.pais:""}
								size="medium"
								margin="normal"
								id="pais"
								label="Pais:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.pais}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.telefono&&this.props.errors.telefono? true:false}
								helperText={this.props.touched.telefono&&this.props.errors.telefono?this.props.errors.telefono:""}
								size="medium"
								margin="normal"
								id="telefono"
								label="Telefono de contacto:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.telefono}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.celular&&this.props.errors.celular? true:false}
								helperText={this.props.touched.celular&&this.props.errors.celular?this.props.errors.celular:""}
								size="medium"
								margin="normal"
								id="celular"
								label="Celular de contacto:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.celular}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.correo&&this.props.errors.correo? true:false}
								helperText={this.props.touched.correo&&this.props.errors.correo?this.props.errors.correo:""}
								size="medium"
								margin="normal"
								id="correo"
								label="Correo Eléctronico:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.correo}
								onBlur={this.props.handleBlur}
							/>
                            </DialogContent>
							     
                    </Dialog>
                    </form>
                       
                )
    }
}
export default  withRouter(Page);
