import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import {Link} from "react-router-dom";
import FaceIcon from '@material-ui/icons/Face';
import Chip from '@material-ui/core/Chip';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import {getFechaHoraString, getFormatoFechaCita} from "../../../utils/date";
import {MASCULINO,FEMENINO} from "../../../utils/constantes";
import {getFecha} from "../../../utils/moment";
import "./estilo.css";


export default class Page extends Component{
 
  constructor(props) {
    super(props);    
  }


    render(){                

        let p=this.props.cita;
        let fecha=this.props.cita.fecha;
        let desde=this.props.cita.horaInicio.substring(0,5);
        let hasta=this.props.cita.horaFin.substring(0,5);
        let nombrePaciente="";

        if(this.props.paciente!=undefined){
          nombrePaciente=this.props.paciente.nombres+" "+this.props.paciente.apellidoPaterno+" "+this.props.paciente.apellidoMaterno;

        }
        let tipoCita="";
        if(this.props.catTipoCita!==undefined){
          tipoCita=this.props.catTipoCita.nombre.toUpperCase();
        }

        let edad="";
        if(this.props.paciente!==undefined){
          edad=this.props.paciente.edad+"a";
        }
        let colorFace="#90a4ae";
        if(this.props.paciente!==undefined){          
          console.log("this.props.paciente.sexo:"+this.props.paciente.sexo);
          if(this.props.paciente.SEXO_id===MASCULINO){
            colorFace="#64b5f6";
          }else if(this.props.paciente.SEXO_id===FEMENINO){
            colorFace="#f06292";
          }  
        }
      
        let codColor=this.props.codColor;

        let newDate = new Date();

		
        /* fecha de busqueda fechaBusqueda={this.props.fechaBusqueda} */
        newDate.setFullYear(fecha.split("-")[0]);
        newDate.setMonth(fecha.split("-")[1]-1);
        newDate.setDate(fecha.split("-")[2]);

        let fechaTitulo =getFecha(fecha);

        console.log("getFecha(fecha):"+getFecha(fecha));
        
        const meses = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.",
        "Jul.", "Ago.", "Sep.", "Oct.", "Nov.", "Dic."];
        let mes = meses[newDate.getMonth()];
        
        const dias = ["dom.","Lun.", "Mar.", "Mie.", "Jue.", "Vie.", "Sab."];
        let diaSem = dias[newDate.getDay()];

        let dia=newDate.getDate();
        let anio=newDate.getFullYear(); 

        let headerFecha="";
        let divider="";

        console.log("this.props.mostrarFecha:"+this.props.mostrarFecha);
        if(this.props.mostrarFecha){
          headerFecha=<ListSubheader  style={{backgroundColor:"#eceff1",borderColor:"#cfd8dc",borderRadius:"0.2rem",borderWidth:"1rem"}}>
                        <Typography type="Raleway" align="right" style={{ color:"#212121",marginLeft:"1rem",fontSize:'0.9rem',fontWeight:'normal',textTransform:"capitalize"}}>
                          {fechaTitulo}
                        </Typography>
                      </ListSubheader>

        }else{
          divider=<Divider variant="middle" style={{color:"blue",backgroundColor:"#eceff1"}}/>;

        }
      

        return  <div>
              
                {headerFecha}
                {divider}
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload: p
                  }
                  }} >
                    <ListItemAvatar>
						              <FaceIcon color="#xxxx" className="face_icon" style={{ color:colorFace,marginRight:".8rem", padding:"0rem",width: "3.3rem",height: "3.3rem"}} color="action" alt={p.MEDICO_id} />
                    </ListItemAvatar>
                    <ListItemText id={p.id} 
                          primary={
                                    <Typography className="text-info" type="Raleway" style={{ marginLeft:"0rem",color: '#000000',fontSize:'1.0rem',fontWeight:'normal',textTransform:"capitalize"}}>
                                      {nombrePaciente}
                                    </Typography>
                                    
                                  }
					      			    secondary={
                                      <Typography type="Raleway" style={{color: '#6c757d', fontSize:'0.5rem',fontWeight:'normal' }}>                                       
                                        <Grid container >
                                        <Grid item className="grid-item">
                                          <Chip                                            
                                            size="small"
                                            color="primary"
                                            icon={<QueryBuilderIcon />} 
                                            style={{backgroundColor:"#546e7a",color:"white"}}
                                            label={desde+" - "+hasta} 
                                          />
                                        </Grid>                               
                                        <Grid item className="grid-item">
                                          <Chip 
                                            label={tipoCita} 
                                            size="small" 
                                            style={{backgroundColor: this.props.codColor ,color:"white"}} 
                                            />  
                                        </Grid>                               

                                        </Grid>
                                      </Typography>
                                     } />
                      <ListItemSecondaryAction >
                        <Badge
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          badgeContent={edad} color='secondary' />                      
                      </ListItemSecondaryAction>
                </ListItem>
                </div>
    }
}
