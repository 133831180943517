import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PACIENTE,CITA, MEDICO} from "../constants/action-type";
import { CONSULTAR_CITAES, AGREGAR_CITA, ELIMINAR_CITA } from "../constants/Cita-action-type";
import { CONSULTAR_MEDICOS } from "../constants/Medico-action-type";
import {PROFIL_MEDICO} from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createCitaRestMiddleware =(citaURL,medicoURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [CITA]: new RestDataSource(citaURL),
        [MEDICO]: new RestDataSource(medicoURL),
        [PACIENTE]: new RestDataSource(pacienteURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de createCitaRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_CITAES:

                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_MEDICO){ //SE REALIZA LA BUSQUEDA DE LAS CITAS DEL MEDICO POR SU ID.
                        var datosTipoCitasByMedico ={};
                        datosTipoCitasByMedico['MEDICO_id']=getState().MedicoUsuarioReducer.medicoUsuario[0].MEDICO_id;
                        dataSources[action.dataType].GetDataParams((dataCita,mensaje)=>{ 
                            if(dataCita===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_CITAES,
                                dataType:action.dataType,
                                payload:dataCita}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataCita.length});
                                }
                            }
                        },datosTipoCitasByMedico); 
                    }
/*                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                        dataSources[PERSONA].GetData((dataPersona,mensaje)=>{ 
                            if(dataPersona===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_PERSONAES,
                                dataType:PERSONA,
                                payload:dataPersona}); 
                                dataSources[PACIENTE].GetData((dataPaciente,mensaje)=>{ 
                                    if(dataPaciente===null){
                                        if(action.loading){next({type:PETICION_WS_ERROR});}
                                    }else{
                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                        type:CONSULTAR_PACIENTEES,
                                        dataType:PACIENTE,
                                        payload:dataPaciente}); 
                                        ////////Se invoca medicos 
                                        dataSources[MEDICO].GetData((dataMedico,mensaje)=>{ 
                                            if(dataMedico===null){
                                                if(action.loading){next({type:PETICION_WS_ERROR});}
                                            }else{
                                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                type:CONSULTAR_MEDICOS,
                                                dataType:MEDICO,
                                                payload:dataMedico}); 
                                            /////////// Se invoca citas 
                                                dataSources[action.dataType].GetData((dataCita,mensaje)=>{ 
                                                    if(dataCita===null){
                                                        if(action.loading){next({type:PETICION_WS_ERROR});}
                                                    }else{
                                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                        type:CONSULTAR_CITAES,
                                                        dataType:action.dataType,
                                                        payload:dataCita}); 
                                                        if(action.loading){
                                                            next({type:PETICION_WS_EXITOSA});
                                                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataCita.length});
                                                        }
                                                    }
                                                });   
                                            }
                                        });   
                                        
                                    }
                                });   
                            }                     
                        });
*/

                           
                break;   

                case AGREGAR_CITA:
                    next({type:PETICION_WS_SOLICITANDO});

                    console.log("AGREGAR_CITA (persona):"+JSON.stringify(action.payload));
                    console.log("AGREGAR_CITA (paciente):"+JSON.stringify(action.payload));
                    dataSources[action.dataType].SetData((dataCita,mensaje)=>{ 
                        if(dataCita===null){
                            console.log("FALLA PETICION A AGREGAR_CITA:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("AGREGAR_CITA (data):"+dataCita);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_CITA,
                                dataType:action.dataType,
                                payload:dataCita});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);                                               
                 break; 

                 case ELIMINAR_CITA:
                    next({type:PETICION_WS_SOLICITANDO});

                    console.log("ELIMINAR_CITA (persona):"+JSON.stringify(action.payload));
                    console.log("ELIMINAR_CITA (paciente):"+JSON.stringify(action.payload));
                    var idCita={};
                    idCita["id"]=action.payload.id;
                    dataSources[action.dataType].DeleteDataParams((dataCita,mensaje)=>{ 
                        if(dataCita===null){
                            console.log("FALLA PETICION A AGREGAR_CITA:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                        }else{
                            console.log("ELIMINAR_CITA (data):"+dataCita);
                            if(dataCita===true){
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ELIMINAR_CITA,
                                    dataType:action.dataType,
                                    payload:action.payload.id});
                                    
                                let count=parseInt( getState().estadosReducer.registros) + 1;
                                console.log("coun:"+count); 
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
    
                            }
        
                        }
                    },idCita);                                               
                 break; 


        default:
                next(action);

    }
}

}

export default createCitaRestMiddleware;