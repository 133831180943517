import {TIPO_DE_DATO} from "../constants/action-type"; 
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.estados,action){

    console.log("ESTADO (PETICION EXITOSA) "+action.dataType);
    switch(action.type){
            
        case TIPO_DE_DATO:
            return {
                ...state,
                selectedType:action.dataType,
                registros:action.registros
            }   
                        
            
        default:
            return state||estadoInicial.estados;
    }

}