import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CITA,RECORDATORIO,CAT_ESTADO_CITA,CAT_TIPO_CITA,PACIENTE,MEDICO,ESQUEMA_TRABAJO,ESQUEMA_TIPO_CITA,MEDICO_USUARIO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {obtenerCatEstadoCitaListWSAction,agregarCatEstadoCitaWSAction,actualizarCatEstadoCitaWSAction,eliminarCatEstadoCitaWSAction} from "../../../redux/actions/CatEstadoCitaActions";
import {obtenerCatTipoCitaListWSAction,agregarCatTipoCitaWSAction,actualizarCatTipoCitaWSAction,eliminarCatTipoCitaWSAction} from "../../../redux/actions/CatTipoCitaActions";
import {obtenerRecordatorioListWSAction,agregarRecordatorioWSAction,actualizarRecordatorioWSAction,eliminarRecordatorioWSAction} from "../../../redux/actions/RecordatorioActions";
import {obtenerCitaListWSAction,agregarCitaWSAction,actualizarCitaWSAction,eliminarCitaWSAction} from "../../../redux/actions/CitaActions";
import {obtenerEsquemaTrabajoListWSAction} from "../../../redux/actions/EsquemaTrabajoActions";
import {obtenerEsquemaTipoCitaListWSAction} from "../../../redux/actions/EsquemaTipoCitaActions";


const validationSchema = yup.object({
		MEDICO_id: yup.string().required("Campo requerido"),
		PACIENTE_id: yup.string().required("Campo requerido"),
		CAT_ESTADO_CITA_id: yup.string().required("Campo requerido"),
		CAT_TIPO_CITA_id: yup.string().required("Campo requerido"),
		fecha: yup.string().required("Campo requerido"),
		horaInicio: yup.string().required("Campo requerido"),
		horaFin: yup.string().required("Campo requerido"),
//		RECORDATORIO_id: yup.string().required("Campo requerido")
})

class CitaForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this

     }

     setDuracionTipoCita=(valor)=> {
        this.setState({
            duracionTipoCita:valor
        });
    }

    
    goBack(){
        this.props.history.goBack();
    }
    render(){    
        let idMedico="";
        if(this.props.MedicoUsuario!==undefined){
            idMedico=this.props.MedicoUsuario.MEDICO_id;
        }
        console.log("(idMedico)citas "+idMedico);    

        return <Formik

                    initialValues={{
                        'MEDICO_id': idMedico,
                        'PACIENTE_id': '',
                        'CAT_ESTADO_CITA_id': '1',
                        'CAT_TIPO_CITA_id': '',
                        'fecha':'',
                        'horaInicio': '',
                        'horaFin': '',
                //		'RECORDATORIO_id': ''
                                    }}

                
                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          setSubmitting(this.props.agregarCitaWSAction(CITA,values));
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          this.goBack();

                      }}
                >{({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                    }) => < Page setFieldValue={setFieldValue} 
                                 handleSubmit={handleSubmit} 
                                 handleChange={handleChange} 
                                 handleBlur={handleBlur} 
                                 values={values} 
                                 errors={errors} 
                                 touched={touched} 
                                 pacientes={this.props.pacientes}  
                                 medico={this.props.medico}
                                 catEstadosCita={this.props.catEstadosCita}  
                                 catTipoCita={this.props.catTipoCita} 
                                 recordatorios={this.props.recordatorios}   
                                 modal={this.props.modal} 
                                 cerrarModal={this.props.cerrarModal}
                                 esquemaTrabajos={this.props.esquemaTrabajos}
                                 esquemaTipoCita={this.props.esquemaTipoCita}
                                                        
                    />}
                </Formik> 
    }


    componentDidMount(){            
            this.props.obtenerEsquemaTrabajoListWSAction(ESQUEMA_TRABAJO,false); /* Invoca por primara vez a productos (default) */
            this.props.obtenerEsquemaTipoCitaListWSAction(ESQUEMA_TIPO_CITA,false); /* Invoca por primara vez a productos (default) */	 
            this.props.obtenerCatEstadoCitaListWSAction(CAT_ESTADO_CITA,false);
        	this.props.obtenerCatTipoCitaListWSAction(CAT_TIPO_CITA,false);
            this.props.obtenerRecordatorioListWSAction(RECORDATORIO,false);
    }

    
    componentWillUpdate(){
        console.log("CUANDO SE EJECUTA ESTO >>>> componentWillUpdate:"+this.props.catTipoCita);
        console.log("catTipoCita  (datos):"+JSON.stringify(this.props.catTipoCita));

    }
}

const mapStateToProps=(state)=>({
  esquemaTrabajos:state.EsquemaTrabajoReducer[ESQUEMA_TRABAJO], 
  esquemaTipoCita:state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA],   
  medico:state.MedicoReducer[MEDICO],
  pacientes:state.PacienteReducer[PACIENTE],
  catEstadosCita:state.CatEstadoCitaReducer[CAT_ESTADO_CITA],
  catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA],
  recordatorios:state.RecordatorioReducer[RECORDATORIO],
  MedicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0],  

})


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerEsquemaTrabajoListWSAction: (type,loading) => dispatch(obtenerEsquemaTrabajoListWSAction(type,loading)),
        obtenerEsquemaTipoCitaListWSAction: (type,loading) => dispatch(obtenerEsquemaTipoCitaListWSAction(type,loading)),
        obtenerCatEstadoCitaListWSAction: (type,loading) => dispatch(obtenerCatEstadoCitaListWSAction(type,loading)),
        obtenerCatTipoCitaListWSAction: (type,loading) => dispatch(obtenerCatTipoCitaListWSAction(type,loading)),
        obtenerRecordatorioListWSAction: (type,loading) => dispatch(obtenerRecordatorioListWSAction(type,loading)),
        agregarCitaWSAction: (type,payload) => dispatch(agregarCitaWSAction(type,payload)),
    }
}


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CitaForm));