import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {PACIENTE,CAT_TIPO_CITA, MEDICO_USUARIO} from "../../../redux/constants/action-type";
import {autenticacionLogin} from "../../../redux/actions/stateActions";
import {obtenerMedicoUsuarioListWSAction} from "../../../redux/actions/MedicoUsuarioActions";
import {PROFIL_MEDICO,PROFIL_ADMINISTRADOR,PROFIL_PACIENTE} from "../../../utils/constantes"


class Login extends Component{
    render(){    
        return <Page  autenticacionLogin={this.props.autenticacionLogin} errorsForm={this.props.errorsForm} usuario={this.props.usuario}    
                        loading={this.props.loading}  />
    }

    componentWillMount(){
        let autenticado=this.props.autenticado;
        console.log("componentWillMount(login): es autenticado> "+autenticado);
        

        if(autenticado){
            console.log(">>>> NO ESTA AUTENTICADO");
            this.props.history.push('/inicio');
        }
    }

    componentWillUpdate(){
        let autenticado=this.props.autenticado;
        console.log("componentWillUpdate(login): es autenticado> "+autenticado);
        

        if(autenticado){
            if(this.props.profil===PROFIL_MEDICO){
                this.props.history.push('/citas');
            }else if(this.props.profil===PROFIL_ADMINISTRADOR){
                this.props.history.push('/usuarios');
            }else if(this.props.profil===PROFIL_PACIENTE){
                this.props.history.push('/presionArterial');
            }else{
                this.props.history.push('/inicio');
            }


        //Obtener profil
        //Si es MEDICO validar si tiene TipoCita
    /*    if(this.props.profil===PROFIL_MEDICO){//Si no tiene TipoCita mandar a /TipoCita

            console.log(">>>> ESTA AUTENTICADO Y USUARIO NUEVO "+this.props.idMedico);
            console.log(">>>> ESTA AUTENTICADO Y USUARIO NUEVO "+this.props.catTipoCitaes.length);
            
            
         if(this.props.catTipoCitaes.length===0){
            this.props.history.push('/tipoCita');
         }else{
            this.props.history.push('/citas');
         }

        }else{
            console.log(">>>> ESTA AUTENTICADO");
            this.props.history.push('/citas');

        }
        */

        }
    }
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    usuario:state.loginReducer.usuario,
    loading:state.loginReducer.loading,
    errorsForm:state.loginReducer.errors,
    autenticado:state.autenticacionReducer.autenticado, 
    profil:state.autenticacionReducer.profil,     
    pacientes:state.datosReducer[PACIENTE],       
    openModalPaciente:state.estadosReducer.openModalPaciente,
    catTipoCitaes:state.CatTipoCitaReducer[CAT_TIPO_CITA]    

})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        autenticacionLogin: (datos) => dispatch(autenticacionLogin(datos))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);
