import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {logout} from "../../redux/actions/autenticacionActions"


class Navbar extends Component{
    render(){        
        return  <Page autenticado={this.props.autenticado} logout={this.props.logout} tipoSeleccion={this.props.tipoSeleccion} registros={this.props.registros}/>    
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => dispatch(logout())
    }
}

const mapStateToProps=(state)=>({
    autenticado:state.autenticacionReducer.autenticado,    
    tipoSeleccion:state.estadosReducer.selectedType,
    registros:state.estadosReducer.registros,

})

export default connect(mapStateToProps,mapDispatchToProps)(Navbar);
