import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PACIENTE,CITA, CAT_ESTADO_CITA} from "../constants/action-type";
import { CONSULTAR_CITAES } from "../constants/Cita-action-type";
import { CONSULTAR_CAT_ESTADO_CITAES } from "../constants/CatEstadoCita-action-type";


/* Funcion para loggin llamada logMiddleware */
const createCatEstadoCitaRestMiddleware =(catEstadoCitaURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [CAT_ESTADO_CITA]: new RestDataSource(catEstadoCitaURL),
        [PACIENTE]: new RestDataSource(pacienteURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_CAT_ESTADO_CITAES:
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                            dataSources[action.dataType].GetData((dataCatEstadoCita,mensaje)=>{ 
                                if(dataCatEstadoCita===null){
                                    if(action.loading){next({type:PETICION_WS_ERROR});}
                                }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_CAT_ESTADO_CITAES,
                                    dataType:action.dataType,
                                    payload:dataCatEstadoCita}); 
                                    if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataCatEstadoCita.length});
                                    }
                                }
                            });   
                break;   

              

        default:
                next(action);

    }
}

}

export default createCatEstadoCitaRestMiddleware;