import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { ESQUEMA_TIPO_CITA_HORARIO,CAT_TIPO_CITA,HORARIO } from "../../../redux/constants/action-type";
import {obtenerEsquemaTipocitaHorarioListWSAction} from "../../../redux/actions/EsquemaTipoCitaHorarioActions";
import {agregarEsquemaTipocitaHorarioWSAction} from "../../../redux/actions/EsquemaTipoCitaHorarioActions";
import {obtenerHorarioListWSAction} from "../../../redux/actions/HorarioActions";



class TipoCitaCardListItemForm extends Component{
    constructor(props) {
        super(props);
        this.state={
                openPeriodoDialog:false,  /* [23Nov2020] Abre el Dialog para selecion del horario */
                dia:"x",
                idDia:"y",
                esquemaTipoCitaHorario:{}, /* [23Nov2020] CONTIENEN LOS DATOS QUE SERAN ENVIADOS AL WS AL AGREGAR UN NUEVO HORARIO */
                horario:{},
                periodo:{"horaInicial":"09:00","horaFinal":"12:00"},

        };

    }
    setOpenPeriodoDialog=(valor)=>{
        this.setState({
            openPeriodoDialog:valor
        });
    }
    setDia=(valor)=>{
        console.log("setDia;"+valor);

        this.setState({
            dia:valor
        });
    }
    setIdDia=(valor)=>{
        console.log("setIdDia;"+valor);
        this.setState({
            idDia:valor
        });
    }

    /** [23Nov2020] CONTIENEN LOS DATOS QUE SERAN ENVIADOS AL WS AL AGREGAR UN NUEVO HORARIO ***/

    setEsquemaTipoCitaHorario=(valor)=>{
        this.setState(
            {
                esquemaTipoCitaHorario:valor
            }
        )
    }

    setPeriodo=(valor)=>{
        this.setState(
            {
                periodo:valor
            }
        )
    }


    setHorario=(valor)=>{
        this.setState(
            {
                horario:valor
            }
        )
    }

    handleClick=()=>{

        console.log("this.state this.state -"+JSON.stringify(this.state));
        this.props.agregarEsquemaTipocitaHorarioWSAction(ESQUEMA_TIPO_CITA_HORARIO,this.state);
    }

    render(){  
    //    console.log("STATE TIPO CITA:>><<"+JSON.stringify(this.state));
        
        return<Page     cat_dia_semana={this.props.cat_dia_semana} 
                        openPeriodoDialog={this.state.openPeriodoDialog} 
                        setOpenPeriodoDialog={this.setOpenPeriodoDialog} 
                        setEsquemaTipoCitaHorario={this.setEsquemaTipoCitaHorario}
                        setHorario={this.setHorario}
                        periodo={this.state.periodo}
                        setPeriodo={this.setPeriodo}
                        handleClick={this.handleClick}
                        esquemaTipoCitaItem={this.props.esquemaTipoCitaItem}
                        setDia={this.setDia}   
                        dia={this.state.dia} 
                        setIdDia={this.setIdDia}   
                        idDia={this.state.idDia} 
                        cat_tipo_cita={this.props.cat_tipo_cita}
                        esquemaTipoCitaHorario={this.props.esquemaTipoCitaHorario}
                        
                        />

    }

    componentDidMount(){
        this.props.obtenerEsquemaTipocitaHorarioListWSAction(ESQUEMA_TIPO_CITA_HORARIO,false);
        this.props.obtenerHorarioListWSAction(HORARIO,false);

//        console.log("STATE TIPO CITA:>><<"+this.props.esquemaTipoCitaItem);

    }
}

const mapStateToProps=(state,ownProps)=>({
    esquemaTipoCitaHorario:state.EsquemaTipoCitaHorarioReducer[ESQUEMA_TIPO_CITA_HORARIO].filter(e => e.ESQUEMA_TIPO_CITA_id === ownProps.esquemaTipoCitaItem.id),
    cat_tipo_cita:state.CatTipoCitaReducer[CAT_TIPO_CITA],
})

const mapDispatchToProps=(dispatch)=>({
    obtenerEsquemaTipocitaHorarioListWSAction: (type,loading) => dispatch(obtenerEsquemaTipocitaHorarioListWSAction(type,loading)),
    agregarEsquemaTipocitaHorarioWSAction: (type,loading) => dispatch(agregarEsquemaTipocitaHorarioWSAction(type,loading)),
    obtenerHorarioListWSAction: (type,loading) => dispatch(obtenerHorarioListWSAction(type,loading)),

})

export default connect(mapStateToProps,mapDispatchToProps)(TipoCitaCardListItemForm);


