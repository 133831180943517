import {RestDataSource} from "./RestDataSource";
import {    AUTENTICACION_LOGIN,LOGIN_EXITO, LOGIN_FALLO, INICIA_LOGIN,USUARIO_ACTUAL, USUARIO_LOGOUT, MOSTRAR_MENSAJE, USUARIO, SET_PROFIL,
        MEDICO_USUARIO,PETICION_WS_ERROR, CAT_TIPO_CITA, MOSTRAR_MENSAJE_TUTORIAL, INSTITUCION, PACIENTE_USUARIO} from "../constants/action-type";
import setAuthorizationToken from "../../utils/setAuthorizationToken";
import jwt from "jsonwebtoken";
import {PROFIL_MEDICO,PROFIL_ADMINISTRADOR,PROFIL_PACIENTE} from "../../utils/constantes"
import {CONSULTAR_MEDICO_USUARIOS_BY_ID} from "../constants/MedicoUsuario-action-type";
import {CONSULTAR_PACIENTE_USUARIO_BY_ID} from "../constants/PacienteUsuario-action-type";
import {CONSULTAR_CAT_TIPO_CITAES_BY_ID} from "../constants/CatTipoCita-action-type";
import { CONSULTAR_INSTITUCIONES } from "../constants/Institucion-action-type";
import {obtenerInstitucionListLocalStoraAction} from "../actions/InstitucionActions";



/* middleware pasa como parameto 2 Url para realizar la consulta antes de enviar la llamada a los reducers
y regresa una funcion
*/

export const createLoginMiddleware =(loginURL,medicoUsuarioURL,pacienteUsuarioURL,catTipoCitaURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [AUTENTICACION_LOGIN]: new RestDataSource(loginURL),
        [MEDICO_USUARIO]: new RestDataSource(medicoUsuarioURL),
        [PACIENTE_USUARIO]: new RestDataSource(pacienteUsuarioURL),
        [CAT_TIPO_CITA]: new RestDataSource(catTipoCitaURL),

    }

/* Realiza el dispacht dependiendo de la accion  */
    return ({dispatch,getState})=>next=>action =>{

        console.log("Dentro de createLoginMiddleware:"+action.type);

        /* Antes de enviar al Reducer */
        switch(action.type){

            /*LOGIN*/
            case AUTENTICACION_LOGIN:
                next({type:INICIA_LOGIN});
                new RestDataSource(loginURL).Login(action.payload).then(
                    (respuesta)=>{    
                        console.log("Respuesta Login:"+JSON.stringify(respuesta.data));
                        console.log("Respuesta Login:"+respuesta.data.errors);

                        if(respuesta.data.errors==undefined){
                            const token =respuesta.data.token;
                            const idUsuario=jwt.decode(token).data.id;
                            localStorage.setItem('jwtToken',token);
                            localStorage.setItem('profil',respuesta.data.profil);
                            setAuthorizationToken(token); 
    
                             /********** SE AGREGA INSTITUCION*/
                             var datosInstitucionArray =[];
                             var datosInstitucion ={};
                             datosInstitucion["id"]=respuesta.data.INSTITUCION_id;
                             datosInstitucionArray[0]=datosInstitucion;
                             localStorage.setItem('institucion',JSON.stringify(datosInstitucionArray));

                             dispatch(obtenerInstitucionListLocalStoraAction(INSTITUCION,JSON.parse(localStorage.institucion)));

                                /* TUTORIAL*/
                                /* SI EL PERFIL ES MEDICO OBTENEMOS EL ID DEL MEDICO DESDE RELACION MEDICO_USUARIO*/
                                if(respuesta.data.profil===PROFIL_MEDICO){
                                    var datosMedicoUsuario ={};
                                    datosMedicoUsuario['idUsuario']=idUsuario;
                                    console.log("Login (id):"+JSON.stringify(datosMedicoUsuario));
        
                                    dataSources[MEDICO_USUARIO].SetData((dataMedicoUsuario,mensaje)=>{ 
                                        if(dataMedicoUsuario===null){
                                            if(action.loading){next({type:PETICION_WS_ERROR});}
                                        }else{
    
                                            console.log("DESDE LOGIN");
                                            localStorage.setItem('medicoUsuario',JSON.stringify(dataMedicoUsuario));
                                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                type:CONSULTAR_MEDICO_USUARIOS_BY_ID,
                                                dataType:MEDICO_USUARIO,
                                                payload:dataMedicoUsuario}); 
                                           
    
                                            /********** AUTENTICAION EXITOSA*/
                                            next({
                                                type:USUARIO_ACTUAL,
                                                payload:jwt.decode(token).data,
                                                profil:respuesta.data.profil
                
                                            });                                                           
                                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                type:LOGIN_EXITO,
                                                payload:action.payload,
                                                profil:"privado"
                                            });

                                        
                                        }
                                    },datosMedicoUsuario)
        
                                }else if(respuesta.data.profil===PROFIL_ADMINISTRADOR){
                                   

                                    /********** AUTENTICAION EXITOSA*/
                                    next({
                                        type:USUARIO_ACTUAL,
                                        payload:jwt.decode(token).data,
                                        profil:respuesta.data.profil
    
                                    });                                                           
                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                        type:LOGIN_EXITO,
                                        payload:action.payload,
                                        profil:"privado"
                                    });
                                }else if(respuesta.data.profil===PROFIL_PACIENTE){
                                    var datosPacienteUsuario ={};
                                    datosPacienteUsuario['idUsuario']=idUsuario;
                                    console.log("Login (dataPacienteUsuario ->id):"+JSON.stringify(datosPacienteUsuario));
        
                                    dataSources[PACIENTE_USUARIO].SetData((dataPacienteUsuario,mensaje)=>{ 
                                        if(dataPacienteUsuario===null){
                                            if(action.loading){next({type:PETICION_WS_ERROR});}
                                        }else{
    
                                            console.log("DESDE LOGIN (dataPacienteUsuario)");
                                            localStorage.setItem('pacienteUsuario',JSON.stringify(dataPacienteUsuario));
                                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                type:CONSULTAR_PACIENTE_USUARIO_BY_ID,
                                                dataType:PACIENTE_USUARIO,
                                                payload:dataPacienteUsuario}); 
                                           
    
                                            /********** AUTENTICAION EXITOSA*/
                                            next({
                                                type:USUARIO_ACTUAL,
                                                payload:jwt.decode(token).data,
                                                profil:respuesta.data.profil
                
                                            });                                                           
                                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                type:LOGIN_EXITO,
                                                payload:action.payload,
                                                profil:"privado"
                                            });

                                        
                                        }
                                    },datosPacienteUsuario)
                                }
    
    
      
                        }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:LOGIN_FALLO,
                                payload:respuesta.data.errors
                            })
                        }

                    },
                    (error)=>{
                        console.log("erros:"+JSON.stringify(error));
                        next({     //por cada registro devuelto del Webservices se envia la funcion next
                            type:LOGIN_FALLO,
                            payload:(typeof error.response !== 'undefined')?error.response.data.errors:"Error inesperado en la conexion de red."
                        })
    
                    }
                    
                    
                );
            break;

            /*LOGOUT*/
            case USUARIO_LOGOUT:
                localStorage.removeItem('jwtToken');
                localStorage.removeItem('profil');
                localStorage.removeItem('medicoUsuario');
                localStorage.removeItem('pacienteUsuario');
                localStorage.removeItem('institucion');

                setAuthorizationToken(false); 
                next({
                    type:USUARIO_LOGOUT
                });           
                console.log("USUARIO_LOGOUT ELIMINANDO DATOS ");
                            
            break;            
           
            default:
                    next(action);

        }
        

    }

}