import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, MEDICO, PACIENTE,INSTITUCION_PERSONA,INSTITUCION_PACIENTE} from "../constants/action-type";
import { AGREGAR_PACIENTE, ACTUALIZAR_PACIENTE, ELIMINAR_PACIENTE,CONSULTAR_PACIENTEES} from "../constants/Paciente-action-type";

import { AGREGAR_INSTITUCION_PACIENTE, ELIMINAR_INSTITUCION_PACIENTE} from "../constants/InstitucionPaciente-action-type";
import { PROFIL_ADMINISTRADOR } from "../../utils/constantes";

/* Funcion para loggin llamada logMiddleware */
const createMedicoRestMiddleware =(pacienteURL,institucionPacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [PACIENTE]: new RestDataSource(pacienteURL),
        [INSTITUCION_PACIENTE]: new RestDataSource(institucionPacienteURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de PACIENTERestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** MEDICO INICIO */                                    
                case CONSULTAR_PACIENTEES:
                            // (21Feb21 Dom.) se implementa la busquesda de pacientes por id selecionado en creacion de USUARIO para el perfil de ADMINSTRADOR)
                            let profil=getState().autenticacionReducer.profil;
                            console.log("Dentro de PACIENTERestMiddleware(payload):"+action.payload);
                            console.log("Dentro de PACIENTERestMiddleware(profil):"+profil);

                            var datosInstitucionPaciente ={};
                            if(profil===PROFIL_ADMINISTRADOR){
                                datosInstitucionPaciente["INSTITUCION_id"]=action.payload;

                            } else{ //(21Feb21 Dom.)Default utilizado actualmente para perfil MEDICO
                                datosInstitucionPaciente["INSTITUCION_id"]=getState().InstitucionReducer.instituciones[0].id;
                            }
                            
                            if(action.loading){
                                next({type:PETICION_WS_SOLICITANDO});
                            }
                            dataSources[action.dataType].GetDataParams((dataPaciente,mensaje)=>{ 
                                if(dataPaciente===null){
                                    console.log("CONSULTAR_PACIENTEES (ERROR):"+JSON.stringify(mensaje));
                                    if(action.loading){next({type:PETICION_WS_ERROR});}
                                }else{
                                    console.log("CONSULTAR_PACIENTEES (dataPaciente):"+JSON.stringify(dataPaciente));

                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_PACIENTEES,
                                    dataType:action.dataType,
                                    payload:dataPaciente}); 
                                    if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataPaciente.length});
                                    }
                                }
                            },datosInstitucionPaciente);    
                break;   

                case AGREGAR_PACIENTE:
                    next({type:PETICION_WS_SOLICITANDO});

       
/*
                            var datosInstitucionPersona ={};
                            datosInstitucionPersona["INSTITUCION_id"]=getState().InstitucionReducer.instituciones[0].id;
                            datosInstitucionPersona["PERSONA_id"]=dataPersona.id;

                            dataSources[INSTITUCION_PACIENTE].SetData((dataInstitucionPersona,mensaje)=>{ 
                                if(dataInstitucionPersona===null){
                                    console.log("FALLA PETICION A MEDICO:"+mensaje);
                                    next({type:PETICION_WS_EXITOSA});
                                }else{
                                    console.log("AGREGAR_PACIENTE (dataInstitucionPersona):"+JSON.stringify(dataInstitucionPersona));
                
                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                        type:AGREGAR_INSTITUCION_PACIENTE,
                                        dataType:INSTITUCION_PACIENTE,
                                        payload:dataInstitucionPersona});                                                     

                                        action.payload.paciente['PERSONA_id'] = dataPersona.id;

                                        console.log("LLAAMDA :"+JSON.stringify(action.payload.paciente));*/
                                        dataSources[action.dataType].SetData((dataPaciente,mensaje)=>{ 
                                            if(dataPaciente===null){
                                                console.log("FALLA PETICION A MEDICO:"+mensaje);
                                                next({type:PETICION_WS_ERROR});
                                            }else{
                                                console.log("AGREGAR_PACIENTE (dataPaciente):"+JSON.stringify(dataPaciente));
                            
                                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                    type:AGREGAR_PACIENTE,
                                                    dataType:action.dataType,
                                                    payload:dataPaciente
                                                });  
                                                    /* Se agregar en tabla relacional INSTITUCION_PACIENTE */  
                                                    var datosInstitucionPaciente ={};
                                                    datosInstitucionPaciente["INSTITUCION_id"]=getState().InstitucionReducer.instituciones[0].id;
                                                    datosInstitucionPaciente["PACIENTE_id"]=dataPaciente.id;       
                                                    dataSources[INSTITUCION_PACIENTE].SetData((dataInstitucionPaciente,mensaje)=>{ 
                                                        if(dataInstitucionPaciente===null){
                                                            console.log("FALLA PETICION A MEDICO:"+mensaje);
                                                            next({type:PETICION_WS_ERROR});
                                                        }else{
                                                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                                type:AGREGAR_INSTITUCION_PACIENTE,
                                                                dataType:INSTITUCION_PACIENTE,
                                                                payload:dataInstitucionPaciente}); 
                                                            let count=parseInt( getState().estadosReducer.registros) + 1;
                                                            console.log("coun:"+count); 
                                                            next({type:PETICION_WS_EXITOSA});
                                                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
            
                                                        }
                                                                                                                              
                                                    },datosInstitucionPaciente);
                                            }
                                        },action.payload);/*
            
                                    }
                            },datosInstitucionPersona);       

                        }
                    },action.payload.persona);     */                           

                 break; 

                 case ACTUALIZAR_PACIENTE:
                    console.log("ACTUALIZAR_PACIENTE(middleware):"+action.payload);

                            next({type:PETICION_WS_SOLICITANDO});
                    
                           /*****/
                           dataSources[action.dataType].UpdateData((dataPaciente,mensaje)=>{ 
                            if(dataPaciente===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_ERROR});
                            }else{
                                console.log("Se actuliza en Axios (dataPaciente):"+dataPaciente);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_PACIENTE,
                                    dataType:action.dataType,
                                    payload:dataPaciente});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);

         
                 break;    

                 case ELIMINAR_PACIENTE:
                    var idPaciente ={};
                    idPaciente["id"]=action.payload.id;
//12Ene2021
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[INSTITUCION_PACIENTE].DeleteDataParams((dataInstitucionPaciente,mensaje)=>{ 
                        if(dataInstitucionPaciente===null){
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"No se elimino el registro!."});  
                        }else{
                            console.log("dataInstitucionPaciente:"+dataInstitucionPaciente);
                                if(dataInstitucionPaciente===true){
                                    console.log("EXITO");
                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                        type:ELIMINAR_INSTITUCION_PACIENTE,
                                        dataType:INSTITUCION_PACIENTE,
                                        payload:action.payload.id
                                    });
                                    dataSources[action.dataType].DeleteDataParams((dataPaciente,mensaje)=>{ 
                                        if(dataPaciente===null){
                                            console.log("FAllo peticion a Servicio:"+mensaje);
                                            next({type:PETICION_WS_ERROR});
                                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"No se elimino el registro!."});  

                                    }else{
                                            console.log("Se Elimina en Axios (dataPaciente):"+JSON.stringify(dataPaciente));
                                            if(dataPaciente===true){
                                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                                    type:ELIMINAR_PACIENTE,
                                                    dataType:action.dataType,
                                                    payload:action.payload.id
                                                });                
                                                
                                                let count=parseInt( getState().estadosReducer.registros) - 1;
                                                console.log("coun(eliminar):"+count); 
                                                next({type:PETICION_WS_EXITOSA});
                                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."});  
                                            }else{
                                                next({type:PETICION_WS_ERROR});
                                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"No se elimino el registro!."});  

                                            }
                            
                                         }
                                    },idPaciente);
                                }else{
                                    console.log("NO SE ELIMINO");
                                }
                            

                        }
                                                                                              
                    },idPaciente);
                    //
                    
        
                    break;  



 

        default:
                next(action);

    }
}

}

export default createMedicoRestMiddleware;