import React,{Component} from "react";
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';


export default class Page extends Component{
/* MUESTRA LOS PERIODOS DE HORARIOS EN LOS COMPONENTES CHIP DE CADA SEMANA*/
   
    render(){
        let periodoChip="";
        if(this.props.periodo!==undefined){
          periodoChip=this.props.periodo.map(h=> 
              <Grid item >
                <Chip label={h.horaInicial +" - "+h.horaFinal}  onDelete={() => {}} style={{margin:"2px"}} />
              </Grid>        
              );
        }
        return  <div>
                    {periodoChip}                        
                </div>
    }
}

