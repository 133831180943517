import React,{Component} from "react";
import {connect} from "react-redux";
import {GLUCOSA} from "../../../redux/constants/action-type";
import {obtenerGlucosaListWSAction,agregarGlucosaWSAction,actualizarGlucosaWSAction,eliminarGlucosaWSAction} from "../../../redux/actions/GlucosaActions";

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReactPDF from '@react-pdf/renderer';
import GlucosaDocument from "../GlucosaDocument"
import ReactDOM from 'react-dom';
import axios from 'axios';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'

class Pages extends Component{

  constructor(props) {
    super(props);
  }


  
    render(){    
 
     let stuff;
      if(this.props.glucosaes.length>0){
        console.log("this.props.presionArteriales :"+this.props.glucosaes);
        stuff =<PDFDownloadLink document={<GlucosaDocument glucosaes={this.props.glucosaes}/>} fileName="glucosa.pdf">
                <PictureAsPdfIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : '')}
              </PDFDownloadLink> 
       }

  
      return (  <Fab
      position="fixed"
      variant="extended"
      size="large"
      color="default"
      aria-label="agregar"
      style={{position: 'fixed', bottom: "5.5rem", right: "1rem",boxShadow:"1", backgroundColor:"#FFFFFF"}}
     
    >
      {stuff}
    </Fab>
      )
                   
      }                    
 /* esta es la accion de middleware*/
 componentDidMount(){            
  
      this.props.obtenerGlucosaListWSAction(GLUCOSA,true); /* Invoca por primara vez a productos (default) */
  
}


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
glucosaes:state.GlucosaReducer[GLUCOSA],    


})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
return {
obtenerGlucosaListWSAction: (type,loading) => dispatch(obtenerGlucosaListWSAction(type,loading)),
 }
}

export default connect(mapStateToProps,mapDispatchToProps)(Pages);