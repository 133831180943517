import React,{Component} from "react";
import Page from "./page";

export default class PeriodoDiaDialog extends Component{
    constructor(props) {
        super(props);
	}


	setHoraInicial=(valor)=>{
		var periodo ={};
		periodo["horaInicial"]=valor;
		periodo["horaFinal"]=this.props.periodo.horaFinal;
		this.props.setPeriodo(periodo);
	}
	  setHoraFinal=(valor)=>{
		var periodo ={};
		periodo["horaInicial"]=this.props.periodo.horaInicial;
		periodo["horaFinal"]=valor;
		this.props.setPeriodo(periodo);
	  }
	 handleClick = () =>  {
		this.props.handleClick();
	}	
	
    render(){    
	
		return <Page setOpenPeriodoDialog={this.props.setOpenPeriodoDialog} 
					 openPeriodoDialog={this.props.openPeriodoDialog}					 
					 dia={this.props.dia}	
					 horaInicial={this.props.periodo.horaInicial} 
					 horaFinal={this.props.periodo.horaFinal} 
					 setHoraInicial={this.setHoraInicial} 
					 setHoraFinal={this.setHoraFinal}					 
					 handleClick={this.props.handleClick}
					 />
    }
    
}

