import React,{Component} from "react";
import Pages from "./page";

 class PresionArterialPDFFloatButton extends Component{
    render(){    
        return <Pages setLoadingReportData={this.props.setLoadingReportData}  loadingReportData={this.props.loadingReportData} presionArteriales={this.props.presionArteriales}/>
    }

}


export default PresionArterialPDFFloatButton;