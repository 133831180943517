import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {INSTITUCION} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido"),
		descripcion: yup.string().required("Campo requerido"),
		direccion: yup.string().required("Campo requerido"),
		razonSocial: yup.string().required("Campo requerido")
})

class InstitucionForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }
    render(){    
    
        return <Formik

                    initialValues={{
		'nombre': '',
		'descripcion': '',
		'direccion': '',
		'razonSocial': ''
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          setSubmitting(this.props.agregarInstitucionWSAction(INSTITUCION,values));
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          this.goBack();

                      }}
                >
                    {props => < Page {...props}  modal={this.props.modal} cerrarModal={this.props.cerrarModal} />}
                </Formik> 
    }
}

export default withRouter(InstitucionForm);


