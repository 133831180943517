import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {MEDICO} from "../../../redux/constants/action-type";
import {obtenerMedicoListWSAction,agregarMedicoWSAction,actualizarMedicoWSAction,eliminarMedicoWSAction} from "../../../redux/actions/MedicoActions";


class Medicos extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'especialidad': '',
					'nombres': '',
					'apellidoPaterno': '',
					'apellidoMaterno': '',
					'cedulaProfesional': '',
					'correo': '',
					'telefono': '',
					'celular': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

     cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }


    render(){        
        return  <Page medicos={this.props.medicos} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarMedicoWSAction={this.props.agregarMedicoWSAction}
                                actualizarMedicoWSAction={this.props.actualizarMedicoWSAction}
                                eliminarMedicoWSAction={this.props.eliminarMedicoWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}/>    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
                this.props.obtenerMedicoListWSAction(MEDICO,true); /* Invoca por primara vez a productos (default) */
            }else{
                console.log("VALORES () > "+JSON.stringify(this.props.location.valores));
                this.setState(this.props.location.valores);
            }

        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    medicos:state.MedicoReducer[MEDICO],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerMedicoListWSAction: (type,loading) => dispatch(obtenerMedicoListWSAction(type,loading)),
        agregarMedicoWSAction: (type,payload) => dispatch(agregarMedicoWSAction(type,payload)),
        actualizarMedicoWSAction: (type,payload) => dispatch(actualizarMedicoWSAction(type,payload)),
        eliminarMedicoWSAction: (type,payload) => dispatch(eliminarMedicoWSAction(type,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Medicos);


