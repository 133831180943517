import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {ESQUEMA_TRABAJO,MEDICO} from "../../../redux/constants/action-type";
import {obtenerMedicoListWSAction,agregarMedicoWSAction,actualizarMedicoWSAction,eliminarMedicoWSAction} from "../../../redux/actions/MedicoActions";
import {obtenerEsquemaTrabajoListWSAction,agregarEsquemaTrabajoWSAction,actualizarEsquemaTrabajoWSAction,eliminarEsquemaTrabajoWSAction} from "../../../redux/actions/EsquemaTrabajoActions";


class EsquemaTrabajos extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
                    'nombre': 'Plan o esquema de trabajo semanal',
                    'descripcion': '',
                    'MEDICO_id': ''
                },
                busqueda:"",
                mostrarCrearEsquema:false

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

     cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }

    mostrarCrearEsquema=(valor)=>{
        this.setState(
            {
              mostrarCrearEsquema:valor  
            }
        );
    }

    render(){   
           
        return  <Page esquemaTrabajos={this.props.esquemaTrabajos} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarEsquemaTrabajoWSAction={this.props.agregarEsquemaTrabajoWSAction}
                                actualizarEsquemaTrabajoWSAction={this.props.actualizarEsquemaTrabajoWSAction}
                                eliminarEsquemaTrabajoWSAction={this.props.eliminarEsquemaTrabajoWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}
                                mostrarCrearEsquema={this.state.mostrarCrearEsquema}  />    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
                this.props.obtenerEsquemaTrabajoListWSAction(ESQUEMA_TRABAJO,true); /* Invoca por primara vez a productos (default) */
            }else{
                this.setState(this.props.location.valores);
            }
            if(this.props.esquemaTrabajos!=undefined){
                if(this.props.esquemaTrabajos.length==0){
                    this.mostrarCrearEsquema(true);
                 }
            }  
        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    esquemaTrabajos:state.EsquemaTrabajoReducer[ESQUEMA_TRABAJO],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerEsquemaTrabajoListWSAction: (type,loading) => dispatch(obtenerEsquemaTrabajoListWSAction(type,loading)),
        obtenerMedicoListWSAction: (type,loading) => dispatch(obtenerMedicoListWSAction(type,loading)),
        agregarEsquemaTrabajoWSAction: (type,payload) => dispatch(agregarEsquemaTrabajoWSAction(type,payload)),
        actualizarEsquemaTrabajoWSAction: (type,payload) => dispatch(actualizarEsquemaTrabajoWSAction(type,payload)),
        eliminarEsquemaTrabajoWSAction: (type,payload) => dispatch(eliminarEsquemaTrabajoWSAction(type,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(EsquemaTrabajos);


