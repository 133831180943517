import React,{Component} from "react";
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';

import PeriodoDiaDialog from "../PeriodoDiaDialog";
import Paper from '@material-ui/core/Paper';
import DiaSemanaCardForm from "../DiaSemanaCardForm";


const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 12,
    spacing: 8,

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
});

export default class Page extends Component{
  
  handleClick=()=>{
    this.props.handleClick();
  }
  
    render(){    

      let nombreEsquemaTipoCita="";
      if(this.props.esquemaTipoCitaItem.CAT_TIPO_CITA_id!==undefined && this.props.cat_tipo_cita!==undefined){
         let cat_tipo_citaItem=this.props.cat_tipo_cita.filter(item => item.id === this.props.esquemaTipoCitaItem.CAT_TIPO_CITA_id );
         console.log("cat_tipo_citaItem>>><<<<"+cat_tipo_citaItem);
         console.log("cat_tipo_citaItem>>><<<<"+cat_tipo_citaItem.length);

         if(cat_tipo_citaItem.length >0){
          nombreEsquemaTipoCita=cat_tipo_citaItem[0].nombre;
         }
        console.log("nombreEsquemaTipoCita>>><<<<"+nombreEsquemaTipoCita);
      }


        return  <Box mt="1rem" >
                    <Accordion>
                      <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{nombreEsquemaTipoCita}</Typography>

                    </AccordionSummary>
                    <AccordionDetails style={{display:"block"}}>
                    <Box mt="1rem">
                    {  /* SE MUESTRAN TODAS LOS DIAS DE LA SEMANA PARQUE SE AGREGEN LOS HORARIOS */
                                    this.props.cat_dia_semana.map(dia_semanaItem=> 
                                      <Paper spacing={3} style={{marginTop:"1rem"}}> 
                                        <DiaSemanaCardForm  esquemaTipoCitaHorario={this.props.esquemaTipoCitaHorario} 
                                                            dia_semanaItem={dia_semanaItem} 
                                                            setOpenPeriodoDialog={this.props.setOpenPeriodoDialog}
                                                            setHorario={this.props.setHorario}
                                                            setEsquemaTipoCitaHorario={this.props.setEsquemaTipoCitaHorario}
                                                            esquemaTipoCitaItem={this.props.esquemaTipoCitaItem}

                                                            />
                                      </Paper>    
                                      )
                          }    
                    </Box>
                    </AccordionDetails>
                    </Accordion>

                    <PeriodoDiaDialog 
                        dia={this.props.dia}
                        setOpenPeriodoDialog={this.props.setOpenPeriodoDialog} 
                        openPeriodoDialog={this.props.openPeriodoDialog} 
                        handleClick={this.handleClick}
                        setPeriodo={this.props.setPeriodo}
                        periodo={this.props.periodo}
                      />
                          
                  </Box>
    }
}

