import React from "react";
import {Link} from "react-router-dom";

import IconButton from '@material-ui/core/IconButton';

import Avatar from '@material-ui/core/Avatar';
import runImg from '../../logo/logo.ico'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EventIcon from '@material-ui/icons/Event';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SnackbarsMensaje from "../SnackbarsMensajeComponent";
import TimelapseIcon from '@material-ui/icons/Timelapse';
import StyleIcon from '@material-ui/icons/Style';
import ListItemComponent from "./ListItemComponent"
import { Popover } from "@material-ui/core";
import PopoverComponent from "../PopoverComponent";
import {getFechaHoyFormato} from "../../utils/date";
import FavoriteIcon from '@material-ui/icons/Favorite';
import OpacityIcon from '@material-ui/icons/Opacity';
import Paper from '@material-ui/core/Paper';


    const Page = (props) => {

      const drawerWidth = 240;

      const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };
      
      const handleDrawerToggleClose = () => {
        setMobileOpen(false);
      };

      const logoutHandler =(e) => {
        props.history.push('/login');
        props.logout();
        props.history.replace("/login");
    
      };

      const useStyles = makeStyles((theme) => ({
            root: {
              display: 'flex',
              backgroundColor:"red"
            },
            drawer: {
              [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,

              },
            },
            appBar: {
              [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
              },
              boxShadow: "none",
              backgroundColor:"#FFFFFF",
              borderWidth:"1",
              marginTop: "0rem"
            },
            menuButton: {
              marginRight: theme.spacing(2),
              [theme.breakpoints.up('sm')]: {
                display: 'none',
              },
            },
            // necessary for content to be below app bar
            toolbar: theme.mixins.toolbar,
            drawerPaper: {
              width: drawerWidth,
            },
            content: {
              flexGrow: 1,
              padding: theme.spacing(3),
              backgroundColor:"red"

            },
          }));

            const classes = useStyles();

            let  encabezado=(<div className={classes.toolbar} >
                                <Container maxWidth="sm">
                                    <Grid container wrap="nowrap" spacing={2} margin={2}>
                                    <Grid item mt={13}>
                                        <Avatar alt="Bildsoft" src={runImg}  style={{width: "3rem",height: "3rem"}}/> 
                                    </Grid>
                                    <Grid item >
                                    <Typography type="Roboto" variant="h6" style={{ color: "#000000",fontSize:"1.2rem",fontWeight:"normal" }}>
                                        <Box  m={1}>
                                            Agendasoft
                                        </Box>
                                    </Typography>
                                    </Grid>
                                    </Grid>
                                </Container>
                            </div>);
            let opcion=props.tipoSeleccion;
            let registros=props.registros;
            let autenticado=props.autenticado;

            const [mobileOpen, setMobileOpen] = React.useState(false);


            const usuario=(<div>
                            
                            <Divider />
                              <List>

                                  <ListItemComponent icon={<FavoriteIcon style={{ width: "2rem",height: "2rem", color:"#2196f3"}}/>}
                                                     opcion={opcion} menu={"presionArterial"} ruta={"/presionArterial"} nombreMenu={"Presión Arterial"} handleDrawerToggleClose={handleDrawerToggleClose}/>

                                  <ListItemComponent icon={<OpacityIcon style={{ width: "2rem",height: "2rem", color:"#f44336"}}/>}
                                                     opcion={opcion} menu={"glucosa"} ruta={"/glucosa"} nombreMenu={"Glucosa"} handleDrawerToggleClose={handleDrawerToggleClose}/>


                                  <ListItemComponent icon={<EventIcon style={{ width: "2rem",height: "2rem", color:"#1e88e5"}}/>}
                                                     opcion={opcion} menu={"Agendados"} ruta={"/citas"} nombreMenu={"Agendados"} handleDrawerToggleClose={handleDrawerToggleClose}/>


                                  <ListItemComponent icon={<GroupIcon style={{ width: "2rem",height: "2rem", color:"#607d8b"}}/>}
                                                     opcion={opcion} menu={"paciente"} ruta={"/pacientes"} nombreMenu={"Pacientes"} handleDrawerToggleClose={handleDrawerToggleClose}/>


                                  <ListItemComponent icon={<AssignmentIndIcon style={{ width: "2rem",height: "2rem", color:"#00bfa5"}}/>}
                                                     opcion={opcion} menu={"medico"} ruta={"/medicos"} nombreMenu={"Medicos"} handleDrawerToggleClose={handleDrawerToggleClose}/>
                                      

                                  <ListItemComponent icon={<SupervisorAccountIcon style={{ width: "2rem",height: "2rem", color:"#8eacbb"}}/>}
                                                     opcion={opcion} menu={"usuarios"} ruta={"/usuarios"} nombreMenu={"Usuarios"} handleDrawerToggleClose={handleDrawerToggleClose}/>


                                  <ListItemComponent icon={<LocalHospitalIcon style={{ width: "2rem",height: "2rem", color:"#00838F"}}/>}
                                                     opcion={opcion} menu={"instituciones"} ruta={"/instituciones"} nombreMenu={"Hospitales"} handleDrawerToggleClose={handleDrawerToggleClose}/>


                                  <ListItemComponent icon={<StyleIcon style={{ width: "2rem",height: "2rem", color:"#f50057"}}/>}
                                                     opcion={opcion} menu={"cat_tipo_cita"} ruta={"/tipoCita"} nombreMenu={"Tipos Citas"} handleDrawerToggleClose={handleDrawerToggleClose}/>


                                  <ListItemComponent icon={<TimelapseIcon style={{ width: "2rem",height: "2rem", color:"#1769aa"}}/>}
                                                     opcion={opcion} menu={"esquemaTrabajo"} ruta={"/esquemas"} nombreMenu={"Esquema de Trabajo"} handleDrawerToggleClose={handleDrawerToggleClose}/>

                                  <ListItem button >
                                      <ListItemIcon><SettingsIcon style={{ width: "2rem",height: "2rem", color:"#757575"}}/></ListItemIcon>
                                      <ListItemText  primary={<Typography type="Roboto" variant="subtitle1" style={{ color: "#000000",fontSize:"1.2rem",fontWeight:"normal" }}>Configuración</Typography>} />
                                  </ListItem>
                                  <ListItem button component={Link} to="/login"  onClick={() => {props.logout();handleDrawerToggleClose();}} >
                                      <ListItemIcon><ExitToAppIcon style={{ width: "2rem",height: "2rem", color:"#babdbe"}}/></ListItemIcon>
                                      <ListItemText  primary={<Typography type="Roboto" variant="subtitle1" style={{ color: "#000000",fontSize:"1.2rem",fontWeight:"normal" }}>Cerrar sesión</Typography>} />
                                  </ListItem>

                              </List>


                            </div>);

                        const invitado=(<div>
                            <Divider />

                            </div>);                            

    const container = window !== undefined ? () => window.document.body : undefined;
    const theme = useTheme();


        return <div className={classes.root} >
               <CssBaseline />
                <AppBar position="fixed"  className={classes.appBar} >
                    <Toolbar  >
                                                    
                    
                    {autenticado?   
                        <IconButton edge="start"  aria-label="menu"  onClick={handleDrawerToggle} className={classes.menuButton}><MenuIcon   color="primary"  style={{  borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} /></IconButton>
                    :<div/>}

                    {autenticado?
                        <Grid  container alignItems="center" style={{width:"70%"}}>                        
                              <Grid item xs={12} alignContent="center" alignItems="center" alignSelf="center">
                                <Box style={{textAlign:"center"}}>
                                <Chip variant="outlined" 
                                    color="primary"   
                                    avatar={<Avatar  
                                                    variant="circle" 
                                                    style={{ backgroundColor:"#2196f3",color:"white"}} 
                                              > 
                                                <Typography 
                                                    type="Roboto"                                                                                   
                                                    style={{ color:"white",fontSize:'0.8rem',fontWeight:'normal',textTransform: "capitalize" }}
                                                >
                                                  {registros}
                                                </Typography>
                                              </Avatar>} 
                                    label={  
                                              <Typography 
                                                  type="Roboto" 
                                                  style={{flexGrow:"1",color: '#6c757d', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}
                                                >
                                                    {opcion==="instituciones"?"Hospitales":opcion}
                                              </Typography>
                                            } 
                                    style={{textAlign:"center",margin:"0.1rem",alignItems:"center",alignContent:"center",alignSelf:"center"}}
                                    size="small"

                                />
                              </Box>
                              </Grid>
                            
                              <Grid item xs={12}> 
                              <Box style={{textAlign:"center"}}>
                                <Chip
                                  label={getFechaHoyFormato()}
                                  color="primary"
                                  size="small"
                                  style={{margin:"0.1rem"}}
                                />
                              </Box>
                              </Grid>
                        </Grid>                        
                    :<div/>}

                    

                    <Hidden xsDown implementation="css">
                        {autenticado?<Button color="default"  onClick={props.logout } href="/login"  >Cerrar Sesion</Button>:<Button color="default" component={Link} to="/login">Login</Button>}
                    </Hidden>    
                    </Toolbar>
                    <Divider />

                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">

                <Hidden smUp implementation="css">
                                            
                    <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                   
                    >
                       {encabezado}
                       {autenticado?usuario:invitado}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {encabezado}
                        {autenticado?usuario:invitado}
                    </Drawer>
                </Hidden>
                <SnackbarsMensaje />

                </nav>        
            </div>    
  //  }
};
export default Page;
