import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function Page(props) {

  const handleClose = () => {
    props.mostrarMensajeTutorial(false,props.mensaje);
  };

  const classes = useStyles();

  return (
    <div>
      
      <Dialog
        open={props.abrirMensaje}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¡Bienvenido!"}</DialogTitle>
        <DialogTitle id="alert-dialog-title">{" Agenda Online Bildsoft"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Typography className={classes.typography} >Para poner inicar con la agenda debes crear al menos un "Tipo de Cita", esto solo se realiza una sola vez.</Typography>

          <Typography className={classes.typography}>Con el "Tipo de Cita" se puede identificar de manera facil dentro de la agenda una cita ya que al crearla le asignas un color. Los tipos de cita pueden ser los sigueintes: CONSULTA DE ESPECIALIDAD, PANENDOSCOPIA, COLONOSCOPIA, CIRUGIA, etc.</Typography>        
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={handleClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
