import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import {MEDICO} from "../../../redux/constants/action-type";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

class Page extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
     
     goBack(){
         this.props.history.goBack();
     }

    guardar=(dato)=> {
        this.props.actualizarMedicoWSAction(MEDICO,dato);
    }

    render(){
        let opcion=this.props.tipoSeleccion;
        const inputProps = {
            step: 300,
            resize:"2rem",
            fontSize:"2rem",
            style: {fontSize: 15} 

          };

        return (
            <form onSubmit={this.props.handleSubmit} style={{margin: 1, width: '25ch'}}> 
                <Dialog
                    open={this.props.modalEditar}
                   // onClose={this.props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen
                    onBackdropClick={this.hola}
                    disableBackdropClick={true}
                    >
                        <AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.goBack} aria-label="close">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                {<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Editar </Typography>}
                            </Typography>
                            <IconButton onClick={this.props.abrirConfirmacion}>
                                <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                            <IconButton >
                            </IconButton>
                            <IconButton edge="start" color="inherit" onClick={this.props.handleSubmit} aria-label="close">
                                <DoneIcon  style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        </AppBar>

                        <DialogTitle id="form-dialog-title">Actualice los datos del registro.</DialogTitle>


							<DialogContent>
							<TextField
								error={this.props.touched.especialidad&&this.props.errors.especialidad? true:false}
								helperText={this.props.touched.especialidad&&this.props.errors.especialidad?this.props.errors.especialidad:""}
								size="medium"
								margin="normal"
								id="especialidad"
								label="especialidad:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.especialidad}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.nombres&&this.props.errors.nombres? true:false}
								helperText={this.props.touched.nombres&&this.props.errors.nombres?this.props.errors.nombres:""}
								size="medium"
								margin="normal"
								id="nombres"
								label="nombres:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.nombres}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.apellidoPaterno&&this.props.errors.apellidoPaterno? true:false}
								helperText={this.props.touched.apellidoPaterno&&this.props.errors.apellidoPaterno?this.props.errors.apellidoPaterno:""}
								size="medium"
								margin="normal"
								id="apellidoPaterno"
								label="apellidoPaterno:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.apellidoPaterno}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.apellidoMaterno&&this.props.errors.apellidoMaterno? true:false}
								helperText={this.props.touched.apellidoMaterno&&this.props.errors.apellidoMaterno?this.props.errors.apellidoMaterno:""}
								size="medium"
								margin="normal"
								id="apellidoMaterno"
								label="apellidoMaterno:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.apellidoMaterno}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.cedulaProfesional&&this.props.errors.cedulaProfesional? true:false}
								helperText={this.props.touched.cedulaProfesional&&this.props.errors.cedulaProfesional?this.props.errors.cedulaProfesional:""}
								size="medium"
								margin="normal"
								id="cedulaProfesional"
								label="cedulaProfesional:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.cedulaProfesional}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.correo&&this.props.errors.correo? true:false}
								helperText={this.props.touched.correo&&this.props.errors.correo?this.props.errors.correo:""}
								size="medium"
								margin="normal"
								id="correo"
								label="correo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.correo}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.telefono&&this.props.errors.telefono? true:false}
								helperText={this.props.touched.telefono&&this.props.errors.telefono?this.props.errors.telefono:""}
								size="medium"
								margin="normal"
								id="telefono"
								label="telefono:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.telefono}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.celular&&this.props.errors.celular? true:false}
								helperText={this.props.touched.celular&&this.props.errors.celular?this.props.errors.celular:""}
								size="medium"
								margin="normal"
								id="celular"
								label="celular:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.celular}
								onBlur={this.props.handleBlur}
							/>
                            
                           
                        </DialogContent>                          
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={this.props.modalConfirmacion}   
                                            cerrarConfirmacion={this.props.cerrarConfirmacion}
                                                eliminar={this.props.eliminar} 
                                                    payload={this.props.payload}/>
                    </form>   
                )
    }
}
export default  withRouter(Page);
