import React,{Component} from "react";
import Page from "./page";

export default class PacientesListItem extends Component{
    render(){    
        return< Page esquemaTrabajo={this.props.esquemaTrabajo} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}

