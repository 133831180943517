import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class Page extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }

    goBack(){
        this.props.history.goBack();
    }


    render(){
        return (<form onSubmit={this.props.handleSubmit}> 
                    <Dialog
                    open={this.props.modal}
                    onClose={this.props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen

                    >
                    <AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.goBack} aria-label="close">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                {<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nuevo Hospital </Typography>}
                            </Typography>

                            <IconButton edge="start" color="inherit" onClick={this.props.handleSubmit} aria-label="close">
                                <DoneIcon  style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        </AppBar>

                        <DialogTitle id="form-dialog-title">Agregar Hospital</DialogTitle>
                        


							<DialogContent>
							<TextField
								error={this.props.touched.nombre&&this.props.errors.nombre? true:false}
								helperText={this.props.touched.nombre&&this.props.errors.nombre?this.props.errors.nombre:""}
								size="medium"
								margin="normal"
								id="nombre"
								label="Nombre del Hospital:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.nombre}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.descripcion&&this.props.errors.descripcion? true:false}
								helperText={this.props.touched.descripcion&&this.props.errors.descripcion?this.props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="Descripcion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.descripcion}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.direccion&&this.props.errors.direccion? true:false}
								helperText={this.props.touched.direccion&&this.props.errors.direccion?this.props.errors.direccion:""}
								size="medium"
								margin="normal"
								id="direccion"
								label="Direccion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.direccion}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.razonSocial&&this.props.errors.razonSocial? true:false}
								helperText={this.props.touched.razonSocial&&this.props.errors.razonSocial?this.props.errors.razonSocial:""}
								size="medium"
								margin="normal"
								id="razonSocial"
								label="Telefonos:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.razonSocial}
								onBlur={this.props.handleBlur}
							/>
                            </DialogContent>
                                         
                    </Dialog>
                    </form>
                       
                )
    }
}
export default  withRouter(Page);
