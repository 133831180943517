export const PRODUCTO="products";
export const PROVEEDOR="suppliers";
export const INSTITUCION="instituciones";
export const INSTITUCION_PACIENTE="institucionesPaciente";
export const USUARIO="usuarios";
export const USUARIO_ROL="usuariosRol";
export const ESTADO="estado";
export const MEDICO="medico";
export const PACIENTE="paciente";
export const CITA="Agendados";
export const RECORDATORIO="recordatorio";
export const ESQUEMA_TRABAJO="esquemaTrabajo";
export const COLOR="color";
export const ESQUEMA_TIPO_CITA="esquemaTipoCita";
export const PERIODO="periodo";
export const HORARIO="horario";
export const ESQUEMA_TIPO_CITA_HORARIO="esquemaTipoCitaHorario";
export const MEDICO_USUARIO="medicoUsuario";
export const PACIENTE_USUARIO="pacienteUsuario";
export const GLUCOSA="glucosa";
export const PRESION_ARTERIAL="presionArterial";

export const CAT_ROL="cat_rol";
export const CAT_ESTADO_CITA="cat_estado_cita";
export const CAT_TIPO_CITA="Tipos Citas Medicas";
export const CAT_DIA_SEMANA="cat_dia_semana";
export const CAT_SEXO="cat_sexo";
export const CAT_UNIDAD_MEDIDA="cat_unidad_medida";


export const STATE_START_EDITING="state_start_editing";
export const STATE_END_EDITING="state_end_editing";
export const STATE_START_CREATING="state_start_creating";

/* model action type */
export const CONSULTAR ="CONSULTAR";
export const UPDATE ="UPDATE";
export const DELETE ="DELETE";

/* model action type */
export const GET_DATOS_WS ="GET_PRODUCTO_WS";
export const GET_PACIENTES_WS ="GET_PACIENTES_WS";

/* loading PETICION WS */
export const PETICION_WS_SOLICITANDO ="SOLICITANDO_PETICION_WS";
export const PETICION_WS_EXITOSA ="EXITOSA_PETICION_WS";
export const PETICION_WS_ERROR ="ERROR_PETICION_WS";

export const INICIA_CREACION_PACIENTE="INICIA_CREACION_PACIENTE";
export const FINALIZA_CREACION_PACIENTE="FINALIZA_CREACION_PACIENTE";

/* LOGIN */

export const INICIA_LOGIN="INICIA_LOGIN";
export const AUTENTICACION_LOGIN="AUTENTICACION_LOGIN";
export const FINALIZA_LOGIN="FINALIZA_LOGIN";
export const LOGIN_FALLO="LOGIN_FALLO";
export const LOGIN_EXITO="LOGIN_EXITO";

/* AUTENTICACION */

export const USUARIO_ACTUAL="USUARIO_ACTUAL";
export const USUARIO_LOGOUT="USUARIO_LOGOUT";


/* Mensaje de notificacion */
export const MOSTRAR_MENSAJE ="MOSTRAR_MENSAJE";

/* Mensaje de notificacion */
export const MOSTRAR_MENSAJE_TUTORIAL ="MOSTRAR_MENSAJE_TUTORIAL";


/* TIpo de dato y numero de registro en titulo */
export const TIPO_DE_DATO ="TIPO_DE_DATO";
export const SET_PROFIL ="SET_PROFIL";

