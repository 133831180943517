import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {Link} from "react-router-dom";

export default class Page extends Component{

    render(){
        
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e"];
        let medico=this.props.medico;

        return  <div>
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload:{medico} 
                  }
                  }} >
                    <ListItemAvatar>
			        		  	<AssignmentIndIcon style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem", color:obtenerColor(medico.especialidad)}} alt={medico.especialidad} />
                    </ListItemAvatar>

				          	<ListItemText id={medico.id} primary={<Typography type="Roboto" style={{ color: '#000000',fontSize:'1rem',fontWeight:'normal' }}>{medico.nombres}</Typography>}
							    	secondary={<Typography type="Roboto" style={{color: '#6c757d', fontSize:'0.9rem',fontWeight:'normal' }}>{medico.apellidoPaterno} {medico.apellidoMaterno}</Typography>} />


                </ListItem>
                <Divider variant="inset" component="li" />

                </div>
    }
}
