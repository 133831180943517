import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {USUARIO,CAT_ROL,INSTITUCION} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {obtenerCatRolListWSAction} from "../../../redux/actions/CatRolActions"
import { CONSULTAR_PACIENTEES } from "../../../redux/constants/Paciente-action-type";
import {ID_ROL_MEDICO} from "../../../utils/constantes"

/*
const validationSchema = yup.object({
		usuario: yup.string().required("Campo requerido"),
		password: yup.string().required("Campo requerido"),
		correo: yup.string().required("Campo requerido"),
		INSTITUCION_id: yup.string().required("Campo requerido"),
		ESTADO_id: yup.string().required("Campo requerido")
})
*/
const validationSchema = yup.object().shape({
    usuario:yup.object().shape({
        usuario: yup.string().required("Campo requerido"),
		password: yup.string().required("Campo requerido"),
		correo: yup.string().required("Campo requerido"),
		INSTITUCION_id: yup.string().required("Campo requerido")
        }),
    rol:yup.object().shape({
        CAT_ROL_id: yup.string().required("Campo requerido"),
        MEDICO_id: yup.string().when("CAT_ROL_id",{ is:ID_ROL_MEDICO,then:yup.string().required("Campo requerido")})
        }),
 /*   medico:yup.object().shape({
        MEDICO_id: yup.string().when("rol.CAT_ROL_id",{ is:"1",then:yup.string().required("Campo requerido")})
        })  */
})

class UsuarioForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }
    render(){    
    
        return <Formik

                    initialValues={{
                        usuario:{'usuario': '',
                                 'password': '',
                                 'correo': '',
                                 'ultimoLogin': '',
                                 'token': '',
                                 'INSTITUCION_id': '',
                                 'ESTADO_id': '1' },
                            rol:{'CAT_ROL_id': '',
                                'MEDICO_id': '',
                                'PACIENTE_id': ''} // 21Feb21Dom - Guarda el id del paciente para este Usuario 
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                        console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          setSubmitting(this.props.agregarUsuarioWSAction(USUARIO,values));
                          resetForm({values:""});
                          this.goBack();

                      }}
                >
                    {props => < Page {...props}  instituciones={this.props.instituciones}  roles={this.props.roles}   modal={this.props.modal} cerrarModal={this.props.cerrarModal} />}
                </Formik> 
    }

    componentDidMount(){
        console.log("obtenerCatRolListWSAction: (desde) componentDidMount")
        this.props.obtenerCatRolListWSAction(CAT_ROL,false); /* Invoca por primara vez a productos (default) */
 
    }
 
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerCatRolListWSAction: (type,loading) => dispatch(obtenerCatRolListWSAction(type,loading)),
    }
}

const mapStateToProps=(state)=>({
  instituciones:state.InstitucionReducer[INSTITUCION],
  roles:state.CatRolReducer[CAT_ROL]
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UsuarioForm));


