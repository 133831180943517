import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PERSONA, PACIENTE,CITA, CAT_TIPO_CITA,COLOR,ESQUEMA_TIPO_CITA, ESQUEMA_TRABAJO} from "../constants/action-type";
import { CONSULTAR_CITAES } from "../constants/Cita-action-type";
import { CONSULTAR_CAT_TIPO_CITAES } from "../constants/CatTipoCita-action-type";
import { AGREGAR_ESQUEMA_TIPO_CITA, CONSULTAR_ESQUEMA_TIPO_CITAES } from "../constants/EsquemaTipoCita-action-type";


/* Funcion para loggin llamada logMiddleware */
const createEsquemaTipoCitaRestMiddleware =(esquemaTipoCitaURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [ESQUEMA_TIPO_CITA]: new RestDataSource(esquemaTipoCitaURL),
        [PACIENTE]: new RestDataSource(pacienteURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_ESQUEMA_TIPO_CITAES:
                    var datosIdByEsquema ={};
                    if(getState().EsquemaTrabajoReducer[ESQUEMA_TRABAJO][0]!==undefined){
                        datosIdByEsquema['ESQUEMA_TRABAJO_id']=getState().EsquemaTrabajoReducer[ESQUEMA_TRABAJO][0].id;
                        console.log("datosIdByEsquema:"+JSON.stringify(datosIdByEsquema));
                        console.log("datosIdByEsquema(action.loading):"+action.loading);
    
                        if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                        dataSources[action.dataType].GetDataParams((dataEsquemaTipoCita,mensaje)=>{ 
                            if(dataEsquemaTipoCita===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_ESQUEMA_TIPO_CITAES,
                                dataType:action.dataType,
                                payload:dataEsquemaTipoCita}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataEsquemaTipoCita.length});
                                }
                            }
                        },datosIdByEsquema);       
                    }
                break;  
                case AGREGAR_ESQUEMA_TIPO_CITA:
                    console.log("PAYLOAD (AGREGAR_ESQUEMA_TIPO_CITA):::>><<"+JSON.stringify(action.payload));

                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                    dataSources[ESQUEMA_TIPO_CITA].SetData((dataEsquemaTipoCita,mensaje)=>{ 
                        if(dataEsquemaTipoCita===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_ESQUEMA_TIPO_CITA,
                                dataType:ESQUEMA_TIPO_CITA,
                                payload:dataEsquemaTipoCita});
                           /*****/
                            next({type:PETICION_WS_EXITOSA});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);
                break;  
        default:
                next(action);

    }
}

}

export default createEsquemaTipoCitaRestMiddleware;