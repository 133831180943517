import React from "react";
import Dashboard from '../dashboard';
import { Route,Switch } from "react-router-dom";
import Login from "../LoginComponent/Login";
import requireAutenticacion from "../../utils/requireAutenticacion"
import Instituciones from "../InstitucionComponent/Institucion";
import Usuarios from "../UsuarioComponent/Usuario";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SnackbarsMensaje from "../SnackbarsMensajeComponent";
import Medicos from "../MedicoComponent/Medico"
import Pacientes from "../PacienteComponent/Paciente"
import Citas from "../CitaComponent/Cita"
import EsquemaTrabajo from "../EsquemaTrabajoComponent/EsquemaTrabajo"
import CatTipoCita from "../CatTipoCitaComponent/CatTipoCita"
import PresionArterial from "../PresionArterialComponent/PresionArterial"
import Glucosa from "../GlucosaComponent/Glucosa"


const Page =()=>{

    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
        },
        drawer: {
          [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
          },
        },
        appBar: {
          [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
          },
        },
        menuButton: {
          marginRight: theme.spacing(2),
          [theme.breakpoints.up('sm')]: {
            display: 'none',
          },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
          width: drawerWidth,
        },
        content: {
          flexGrow: 1,
          padding: theme.spacing(0,1,0,0),
          marginTop: "0rem",
          backgroundColor:"white"
        },
      }));

      const drawerWidth = 240;
      const classes = useStyles();
  
  
        return  <div className={classes.root}  >
                    <nav className={classes.drawer} aria-label="mailbox folders">
                    </nav>
                    <main className={classes.content}>
                    
                        <Route  exact={true} path="/presionArterial" component={requireAutenticacion(PresionArterial)} />
                        <Route  exact={true} path="/glucosa" component={requireAutenticacion(Glucosa)} />
                        <Route  exact={true} path="/citas" component={requireAutenticacion(Citas)} />
                        <Route  exact={true} path="/pacientes" component={requireAutenticacion(Pacientes)} />
                        <Route  exact={true} path="/instituciones" component={requireAutenticacion(Instituciones)} />
                        <Route  exact={true} path="/usuarios" component={requireAutenticacion(Usuarios)} />
                        <Route  exact={true} path="/medicos" component={requireAutenticacion(Medicos)} />
                        <Route  exact={true} path="/tipoCita" component={requireAutenticacion(CatTipoCita)} />                        
                        <Route  exact={true} path="/esquemas" component={requireAutenticacion(EsquemaTrabajo)} />                        
                        <Route  exact={true} path="/inicio" component={Dashboard} />                          
                        <Route  exact={true} path="/login" component={Login} />
                        <Route  exact={true} path="/" component={Login} /> 
                       <SnackbarsMensaje />
                    </main>
                </div>
};

export default Page;






