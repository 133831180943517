import React,{Component} from "react";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReactPDF from '@react-pdf/renderer';
import PresionArterialDocument from "../PresionArterialDocument"
import Documento from "../PresionArterialDocument/page"
import ReactDOM from 'react-dom';
import axios from 'axios';
import {connect} from "react-redux";
import {PRESION_ARTERIAL,PACIENTE,CAT_UNIDAD_MEDIDA} from "../../../redux/constants/action-type";
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import {obtenerPresionArterialListWSAction,agregarPresionArterialWSAction,actualizarPresionArterialWSAction,eliminarPresionArterialWSAction} from "../../../redux/actions/PresionArterialActions";


class Pages extends Component{

  constructor(props) {
    super(props);
  }


  
    render(){    
 
     let stuff;
      if(this.props.presionArterialesList.length>0){
        console.log("this.props.presionArteriales :"+this.props.presionArterialesList);
        stuff =<PDFDownloadLink document={<PresionArterialDocument presionArteriales={this.props.presionArterialesList}/>} fileName="presionArterial.pdf">
                <PictureAsPdfIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : '')}
              </PDFDownloadLink> 
       }

  
      return (  <Fab
      position="fixed"
      variant="extended"
      size="large"
      color="default"
      aria-label="agregar"
      style={{position: 'fixed', bottom: "5.5rem", right: "1rem",boxShadow:"1", backgroundColor:"#FFFFFF"}}
     
    >
      {stuff}
    </Fab>
      )
                   
      }                    
/* esta es la accion de middleware*/
componentDidMount(){            
  
      this.props.obtenerPresionArterialListWSAction(PRESION_ARTERIAL,true); /* Invoca por primara vez a productos (default) */
 
}


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
presionArterialesList:state.PresionArterialReducer[PRESION_ARTERIAL],    


})


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      obtenerPresionArterialListWSAction: (type,loading) => dispatch(obtenerPresionArterialListWSAction(type,loading)),
     
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Pages);