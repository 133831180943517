import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import React, { useState, useEffect,useMemo ,useCallback,useRef} from 'react';
import axios from 'axios';

const borderColor = '#90e5fc'

// Create styles
const styles = StyleSheet.create({
  page: { flexDirection: "column", padding: 25 },
   headerContainer: {
      marginTop: 10
  },
  billTo: {
      fontSize: 11,
      marginTop: 5,
      paddingBottom: 3,
      fontFamily: 'Helvetica-Oblique'
  },
  table: {
    fontSize: 10,
    width: 550,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    marginTop: 15

  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35
  },
  cell: {
    borderColor: "#ffffff",
    borderStyle: "solid",
    borderWidth: 2,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch"
  },
  header: {
    backgroundColor: "#eee"
  },
  headerText: {
    fontSize: 11,
    color: "#1a245c",
    padding:1 
  },
  tableText: {
    padding:3,
    fontSize: 10,
    color: "black"
  },
  fecha: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  hora: {
      width: '15%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  medida: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  notas: {
      width: '50%'
  },

});


// Create Document Component
const Documento = (props) => {
  
return (
<Document>
    <Page style={styles.page} size="A4" wrap>
        <View style={styles.headerContainer}>
            <Text style={styles.billTo}>Paciente:</Text>
            <Text style={styles.billTo}>nombre</Text>
            <Text style={styles.billTo}>edad</Text>
            <Text style={styles.billTo}>fechaNacimiento</Text>
        </View>
        <View style={styles.table}>
              <View style={[styles.row, styles.header]}>
                  <Text style={[styles.fecha,styles.headerText]}>Fecha</Text>
                  <Text style={styles.hora}>Hora</Text>
                  <Text style={styles.medida}>Medida (mg/dl)</Text>
                  <Text style={styles.notas}>Notas</Text>
              </View>
              { props.glucosaes.map((item, index) =>{
                  return (
                  <View style={[styles.row]}>
                    <Text style={[styles.fecha,styles.tableText]}>{item.fecha}</Text>
                    <Text style={[styles.hora,styles.tableText]}>{item.hora}</Text>
                    <Text style={[styles.medida,styles.tableText]}>{item.valor}</Text>
                    <Text style={[styles.notas,styles.tableText]}>{item.nota}</Text>              
                </View>
  
                )})
              }
        </View>
    </Page>
  </Document>)
}

export default  Documento;
