export const ID_ROL_MEDICO="1";
export const ID_ROL_PACIENTE="3";

export const PROFIL_MEDICO="MEDICO";
export const PROFIL_ADMINISTRADOR="ADMINISTRADOR";
export const PROFIL_PACIENTE="PACIENTE";
export const ENTORNO="PRO";
export const FEMENINO="2";
export const MASCULINO="1";

export const UNIDAD_MEDIDA_PRESION_ARTERIAL="1";
export const UNIDAD_MEDIDA_GLUCOSA="2";